import { Column } from "@ant-design/plots";
import { Button, Card, Col, DatePicker, Row, Space, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { formatNumberWithCommas } from "utils/functions";

export default function Efficiency() {
  const [selectedDate, setSelectedDate] = useState(moment());
  // const [data, setData] = useState({
  //   dataSource: [],
  //   loading: false,
  // });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const data = [
    { line: "Line #01", profit: 5 },
    { line: "Line #02", profit: 10 },
    { line: "Line #03", profit: 15 },
    { line: "Line #04", profit: 20 },
    { line: "Line #05", profit: 25 },
    { line: "Line #06", profit: 30 },
    { line: "Line #07", profit: 35 },
    { line: "Line #08", profit: 40 },
    { line: "Line #09", profit: 45 },
    { line: "Line #10", profit: 50 },
    { line: "Line #11", profit: 55 },
    { line: "Line #12", profit: 60 },
    { line: "Line #13", profit: 65 },
    { line: "Line #14", profit: 70 },
    { line: "Line #15", profit: 75 },
    { line: "Line #16", profit: 80 },
    { line: "Line #17", profit: 85 },
    { line: "Line #18", profit: 90 },
    { line: "Line #19", profit: 95 },
    { line: "Line #20", profit: 100 },
    { line: "Line #21", profit: 105 },
    { line: "Line #22", profit: 110 },
    { line: "Line #23", profit: 115 },
    { line: "Line #24", profit: 120 },
    { line: "Line #25", profit: 125 },
    { line: "Line #26", profit: 130 },
    { line: "Line #27", profit: 135 },
    { line: "Line #28", profit: 140 },
    { line: "Line #29", profit: 145 },
    { line: "Line #30", profit: 150 },
    { line: "Line #31", profit: 155 },
    { line: "Line #32", profit: 160 },
    { line: "Line #33", profit: 165 },
    { line: "Line #34", profit: 170 },
    { line: "Line #35", profit: 175 },
    { line: "Line #36", profit: 180 },
    { line: "Line #37", profit: 185 },
    { line: "Line #38", profit: 190 },
  ];

  const config = {
    data,
    xField: "line",
    yField: "profit",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  const dataSource = [
    {
      key: "1",
      sewingLine: "Line #01",
      lineChief: "Mr. A",
      manpower: 30,
      production: 500,
      sam: 7.5,
      workingHour: 8,
      otHourManpower: 0,
      availableMinutes: 480,
      earnMinute: 360,
      actualEfficiency: 75,
      ranking: 1,
    },
    {
      key: "2",
      sewingLine: "Line #02",
      lineChief: "Mr. B",
      manpower: 30,
      production: 500,
      sam: 7.5,
      workingHour: 8,
      otHourManpower: 0,
      availableMinutes: 480,
      earnMinute: 360,
      actualEfficiency: 75,
      ranking: 2,
    },
    {
      key: "3",
      sewingLine: "Line #03",
      lineChief: "Mr. C",
      manpower: 30,
      production: 500,
      sam: 7.5,
      workingHour: 8,
      otHourManpower: 0,
      availableMinutes: 480,
      earnMinute: 360,
      actualEfficiency: 75,
      ranking: 3,
    },
    {
      key: "4",
      sewingLine: "Line #04",
      lineChief: "Mr. D",
      manpower: 30,
      production: 500,
      sam: 7.5,
      workingHour: 8,
      otHourManpower: 0,
      availableMinutes: 480,
      earnMinute: 360,
      actualEfficiency: 75,
      ranking: 4,
    },
    {
      key: "5",
      sewingLine: "Line #05",
      lineChief: "Mr. E",
      manpower: 30,
      production: 500,
      sam: 7.5,
      workingHour: 8,
      otHourManpower: 0,
      availableMinutes: 480,
      earnMinute: 360,
      actualEfficiency: 75,
      ranking: 5,
    },
  ];

  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "sewingLine",
      key: "sewingLine",
    },
    {
      title: "Line Chief",
      dataIndex: "lineChief",
      key: "lineChief",
    },
    {
      title: "Manpower",
      dataIndex: "manpower",
      key: "manpower",
      align: "right",
    },
    {
      title: "Production",
      dataIndex: "production",
      key: "production",
      align: "right",
    },
    {
      title: "SAM",
      dataIndex: "sam",
      key: "sam",
      align: "right",
      render: (text) => formatNumberWithCommas(parseFloat(text).toFixed(2)),
    },
    {
      title: "Working Hour",
      dataIndex: "workingHour",
      key: "workingHour",
      align: "right",
    },
    {
      title: "OT Hour Manpower",
      dataIndex: "otHourManpower",
      key: "otHourManpower",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Available Minutes",
      dataIndex: "availableMinutes",
      key: "availableMinutes",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Earn Minute",
      dataIndex: "earnMinute",
      key: "earnMinute",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Actual Efficiency",
      dataIndex: "actualEfficiency",
      key: "actualEfficiency",
      align: "right",
      render: (text) =>
        `${formatNumberWithCommas(parseFloat(text).toFixed(2))}%`,
    },
    {
      title: "Ranking on Efficiency",
      dataIndex: "ranking",
      key: "ranking",
      align: "center",
    },
  ];

  return (
    <Card
      title={`
        Line wise Daily Efficiency Report for ${selectedDate.format(
          "DD-MMM-YYYY",
        )}
      `}
      bordered={false}
    >
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-YYYY"}
            style={{
              width: "100%",
            }}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={3}>
          <Space>
            <Button
              type="primary"
              danger
              onClick={() => setSelectedDate(moment())}
            >
              Reset
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        summary={(tableData) => {
          let totalManpower = 0;
          let totalProduction = 0;
          let totalSam = 0;
          let totalWorkingHour = 0;
          let totalOtHourManpower = 0;
          let totalAvailableMinutes = 0;
          let totalEarnMinute = 0;
          let totalActualEfficiency = 0;

          tableData.forEach((item) => {
            totalManpower += item.manpower;
            totalProduction += item.production;
            totalSam += parseFloat(item.sam);
            totalWorkingHour += item.workingHour;
            totalOtHourManpower += item.otHourManpower;
            totalAvailableMinutes += item.availableMinutes;
            totalEarnMinute += item.earnMinute;
            totalActualEfficiency += parseFloat(item.actualEfficiency);
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalManpower}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalProduction}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>
                    {formatNumberWithCommas(parseFloat(totalSam).toFixed(2))}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalWorkingHour}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalOtHourManpower}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalAvailableMinutes}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>{totalEarnMinute}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <strong>
                    {formatNumberWithCommas(
                      parseFloat(totalActualEfficiency).toFixed(2),
                    )}
                    %
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Column {...config} />
      </div>
    </Card>
  );
}
