import { Card, Collapse, Form, InputNumber, Select, Table, Typography } from "antd";
import { PostCuttingProcessContext } from "context/PostCuttingProcessContext/PostCuttingProcessContextProvider";
import { useContext } from "react";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";
import CuttingTableFilter from "../CuttingTableFilter";

const PostCuttingDetails = (props) => {
  // Props
  const { isUpdate } = props;

  // Contexts
  const {
    cuttingTableData,
    setCuttingTableData,
    garmentsParts,
    onSelectGarmentsParts,
    onChangeProcessData,
  } = useContext(PostCuttingProcessContext);

  console.log("garmentsParts", garmentsParts);

  const columns = [
    { title: "PO", dataIndex: ["original_po"], key: "original_po" },
    { title: "Style", dataIndex: ["style_info", "style_no"], key: "style" },
    {
      title: "Sub Style",
      dataIndex: ["sub_style_info", "sub_style_no"],
      key: "sub_style",
    },
    { title: "Color", dataIndex: ["color", "name"], key: "color" },
    { title: "Size", dataIndex: ["size", "name"], key: "size" },
    {
      title: "Part",
      key: "part",
      render: (value, record) => {
        return (
          <Select
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            size="small"
            allowClear
            onSelect={(value) => onSelectGarmentsParts(record?.id, value)}
            value={record?.process_products?.garment_part_info?.id}
          >
            {isArrayAndHasValue(garmentsParts) &&
              garmentsParts?.map((item) => {
                return (
                  <Select.Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Select.Option>
                );
              })}
          </Select>
        );
      },
    },
    {
      title: "Send",
      key: "send_qty",
      render: (value, record) => {
        return (
          <InputNumber
            size="small"
            placeholder="Input Number"
            style={{ width: "100%" }}
            formatter={(value) => commaSeparateNumber(value)}
            onChange={(value) =>
              onChangeProcessData(record?.id, value, "send_qty",)
            }
            value={record?.process_products?.send_qty}
          />
        );
      }
    },
    {
      title: "Total Sent",
      dataIndex: ["process_products", "total_send_qty"],
      key: "total_send_qty",
    },
    {
      title: "Total Received",
      dataIndex: ["process_products", "total_receive_qty"],
      key: "total_receive_qty",
    },
    {
      title: "Short",
      dataIndex: ["process_products", "total_short"],
      key: "total_short",
    },
  ];

  console.log('cuttingTableData', cuttingTableData);
  

  const TableSummary = ({ pageData }) => {
    const sendQtySum = pageData?.reduce((total, item) => {
      return total + item?.process_products?.send_qty;
    }, 0);
    const totalSendQtySum = pageData?.reduce((total, item) => {
      return total + item?.process_products?.total_send_qty;
    }, 0);
    const totalReceivedQtySum = pageData?.reduce((total, item) => {
      return total + item?.process_products?.total_receive_qty;
    }, 0);
    const totalShortQtySum = pageData?.reduce((total, item) => {
      return total + item?.process_products?.total_short;
    }, 0);

    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={6} id="total">
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} id="send_qty">
            <Typography.Text>{sendQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} id="total_send_qty">
            <Typography.Text>{totalSendQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} id="total_received_qty">
            <Typography.Text>{totalReceivedQtySum}</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9} id="total_short_qty">
            <Typography.Text>{totalShortQtySum}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <>
      <div className="cuttingTable" style={{ marginTop: 10 }}>
        <Collapse defaultActiveKey={1}>
          <Collapse.Panel header={<b>Table Filter</b>} key={1}>
            <CuttingTableFilter />
          </Collapse.Panel>
        </Collapse>

        <Card style={{ marginTop: 10 }}>
          <Typography.Title style={{ fontSize: 14 }}>
            Embroidery, Printing, Smocking and Pintuck Details
          </Typography.Title>
          <Table
            pagination={true}
            size={"small"}
            sticky={true}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={cuttingTableData}
            columns={columns}
            summary={(pageData) => {
              return <TableSummary pageData={pageData} />;
            }}
          />
        </Card>
      </div>
    </>
  );
};
export default PostCuttingDetails;
