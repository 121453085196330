import { Col, Form, Input, InputNumber, message, Row, Select } from "antd";
import {
  GET_SALES_CONTRACT_LIST,
  IMPORT_EXPENSE_SALES_CONTRACT,
  IMPORT_INVOICE_EXPENSE_LIST,
  IMPORT_LC_TRACKER,
} from "apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "apiServices/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { isArrayAndHasValue } from "utils/functions";

const { Option } = Select;

const ImportLcExpenseForm = ({ form, mode = "create" }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    salesContract: [],
    b2bLcInfo: [],
    buyerInfo: [],
    supplierInfo: [],
  });
  const [editData, setEditData] = useState(null);
  const [b2bLcLoaded, setB2bLcLoaded] = useState(false);
  // Add state to prevent multiple API calls
  const [apiCallsInProgress, setApiCallsInProgress] = useState({
    salesContract: false,
    editData: false,
  });

  // Calculate acceptance commission based on import value
  const calculateAcceptanceCommission = useCallback((importValue) => {
    const commission = importValue * 0.001; // 0.1%
    return Math.max(commission, 1000); // Minimum 1000
  }, []);

  // Function to calculate total from all relevant fields
  const calculateTotal = useCallback(() => {
    const values = form.getFieldsValue();
    const total =
      (values.lc_value || 0) +
      (values.amendment_charges || 0) +
      (values.lc_stamp_charges || 0) +
      (values.issue_commission || 0) +
      (values.swift_charges || 0);

    form.setFieldsValue({ total: total.toFixed(2) });
    return total.toFixed(2);
  }, [form]);

  // Handle B2B LC change and update form values
  const handleB2BLcChange = useCallback(
    (b2bLcId) => {
      if (!b2bLcId) return;

      const selectedB2BLc = options.b2bLcInfo.find(
        (b2bLc) => b2bLc.id === b2bLcId,
      );

      if (selectedB2BLc) {
        const importValue = selectedB2BLc.total_lc_value || 0;
        const acceptanceCommission = calculateAcceptanceCommission(importValue);

        form.setFieldsValue({
          supplier_id: selectedB2BLc.supplier_id,
          lc_value: importValue,
          issue_commission: acceptanceCommission,
          lc_stamp_charges: 500, // Ensure stamp charges is set
        });

        // Recalculate total
        setTimeout(calculateTotal, 0);
      }
    },
    [options.b2bLcInfo, form, calculateAcceptanceCommission, calculateTotal],
  );

  // Handle sales contract change and fetch related data
  const handleSalesContractChange = useCallback(
    async (contractId) => {
      if (!contractId || apiCallsInProgress.salesContract) return;

      try {
        setApiCallsInProgress((prev) => ({ ...prev, salesContract: true }));

        // Reset relevant fields when sales contract changes
        form.setFieldsValue({
          buyer_id: undefined,
          supplier_id: undefined,
          btb_lc_no: undefined,
          lc_value: 0,
          issue_commission: 0,
          total: 0,
        });

        const customQuery = `${IMPORT_EXPENSE_SALES_CONTRACT}/${contractId}`;
        const res = await getData(customQuery, false, null);

        if (res?.data) {
          const contractData = res.data;

          // Get the B2B LC info directly
          const b2bLcData = contractData?.b2b_lc_info_data || [];

          // Update options
          setOptions((prev) => ({
            ...prev,
            buyerInfo: contractData?.buyer ? [contractData.buyer] : [],
            supplierInfo: b2bLcData
              .map((item) => item.supplier_info)
              .filter(Boolean),
            b2bLcInfo: b2bLcData,
          }));

          setB2bLcLoaded(true);

          // Always set buyer when sales contract changes
          if (contractData?.buyer) {
            form.setFieldsValue({
              buyer_id: contractData.buyer.id,
            });
          }

          // In edit mode with edited data, select the correct B2B LC only if we're loading initial data
          if (
            (mode === "edit" || mode === "view") &&
            editData &&
            !form.getFieldValue("btb_lc_no")
          ) {
            setTimeout(() => {
              // Find the B2B LC that matches our editData btb_lc_no
              const matchedB2BLc = b2bLcData.find(
                (lc) => lc.id.toString() === editData.btb_lc_no.toString(),
              );

              if (matchedB2BLc) {
                form.setFieldsValue({ btb_lc_no: matchedB2BLc.id });
                handleB2BLcChange(matchedB2BLc.id);
              } else {
                console.log(
                  "No matching B2B LC found for ID:",
                  editData.btb_lc_no,
                );
              }
            }, 100);
          }

          // Recalculate total after setting values
          setTimeout(calculateTotal, 150);
        }
      } catch (error) {
      } finally {
        setApiCallsInProgress((prev) => ({ ...prev, salesContract: false }));
      }
    },
    [
      form,
      mode,
      editData,
      handleB2BLcChange,
      apiCallsInProgress.salesContract,
      calculateTotal,
    ],
  );

  // Fetch sales contracts - only called once
  const fetchSalesContracts = useCallback(async (filterObject = {}) => {
    const customQuery = `${GET_SALES_CONTRACT_LIST}?page=1&per_page=20`;
    const res = await getData(customQuery, false, filterObject);

    if (res?.data?.data?.data) {
      setOptions((prev) => ({
        ...prev,
        salesContract: res.data.data.data,
      }));
    }
  }, []);

  // Handle import value changes
  const handleImportValueChange = useCallback(
    (value) => {
      if (value) {
        form.setFieldsValue({
          issue_commission: calculateAcceptanceCommission(value),
        });
        calculateTotal();
      }
    },
    [form, calculateAcceptanceCommission, calculateTotal],
  );

  // Initialize form for edit mode
  const fetchEditData = useCallback(async () => {
    if (
      !id ||
      !(mode === "edit" || mode === "view") ||
      apiCallsInProgress.editData
    )
      return;

    try {
      setApiCallsInProgress((prev) => ({ ...prev, editData: true }));

      const customQuery = `${IMPORT_LC_TRACKER}/${id}/edit`;
      const res = await getData(customQuery, false, {});

      if (res?.data?.data) {
        const masterData = res.data.data;

        // Store edit data for later use
        setEditData(masterData);

        // Fetch sales contract data to get related information
        const salesContractId = masterData.sales_contract_id;

        // Set form values immediately except for btb_lc_no which depends on loaded B2B LC info
        form.setFieldsValue({
          sales_contract_id: salesContractId,
          buyer_id: masterData.buyer_id,
          supplier_id: masterData.supplier_id,
          lc_value: masterData.lc_value || 0,
          sg_insurance_charges: masterData.sg_insurance_charges || 0,
          paid_transport_bill: masterData.paid_transport_bill || 0,
          air_cndf: masterData.air_cndf || 0,
          potential_oppotnity_saving_cndf:
            masterData.potential_oppotnity_saving_cndf || 0,
          paid_cndf_bill: masterData.paid_cndf_bill || 0,
          edf_interest: masterData.edf_interest || 0,
          issue_commission: calculateAcceptanceCommission(
            masterData.lc_value || 0,
          ),
          swift_charges: masterData.swift_charges || 0,
          amendment_charges: masterData.amendment_charges || 0,
        });

        // Now fetch the sales contract data which will trigger B2B LC selection in handleSalesContractChange
        await handleSalesContractChange(salesContractId);

        // Calculate total
        setTimeout(calculateTotal, 150);
      }
    } catch (error) {
    } finally {
      setApiCallsInProgress((prev) => ({ ...prev, editData: false }));
    }
  }, [
    id,
    mode,
    form,
    handleSalesContractChange,
    calculateAcceptanceCommission,
    calculateTotal,
    apiCallsInProgress.editData,
  ]);

  // Initialize form for create mode
  const initializeCreateForm = useCallback(() => {
    if (id || mode === "edit") return;

    // Set default values for numeric fields
    const defaultValues = {
      lc_value: undefined,
      amendment_charges: undefined,
      lc_stamp_charges: 500,
      issue_commission: undefined,
      swift_charges: undefined,
      total: undefined,
    };

    form.setFieldsValue(defaultValues);
    calculateTotal();
  }, [id, mode, form, calculateTotal]);

  // Use effect to monitor when B2B LCs are loaded and editData exists
  useEffect(() => {
    if (b2bLcLoaded && editData && (mode === "edit" || mode === "view")) {
      // Try to find and select the correct B2B LC
      const matchedB2BLc = options.b2bLcInfo.find(
        (lc) => lc.id.toString() === editData.btb_lc_no?.toString(),
      );

      if (matchedB2BLc) {
        form.setFieldsValue({ btb_lc_no: matchedB2BLc.id });
        handleB2BLcChange(matchedB2BLc.id);
      }
    }
  }, [b2bLcLoaded, editData, options.b2bLcInfo, form, handleB2BLcChange, mode]);

  // Initialize component
  useEffect(() => {
    fetchSalesContracts();

    if ((mode === "edit" || mode === "view") && id) {
      fetchEditData();
    } else {
      initializeCreateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array means this runs once on mount

  // Remove the dependency on options.b2bLcInfo as it can cause extra renders
  useEffect(() => {
    if (b2bLcLoaded && editData && (mode === "edit" || mode === "view")) {
      // Try to find and select the correct B2B LC only if we haven't already done so
      const currentB2BLc = form.getFieldValue("btb_lc_no");
      if (!currentB2BLc) {
        const matchedB2BLc = options.b2bLcInfo.find(
          (lc) => lc.id.toString() === editData.btb_lc_no?.toString(),
        );

        if (matchedB2BLc) {
          form.setFieldsValue({ btb_lc_no: matchedB2BLc.id });
          handleB2BLcChange(matchedB2BLc.id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [b2bLcLoaded, editData]);

  const onFinish = async (values) => {
    try {
      // Ensure lc_stamp_charges is included in the payload
      const formData = {
        ...values,
        lc_stamp_charges: values.lc_stamp_charges || 500, // Default to 500 if not set
      };

      if (!id) {
        const response = await postData(IMPORT_LC_TRACKER, formData);
        if (response) {
          message.success("Data added successfully");
          navigate("/import-export-expense-tracking/import-lc-expense");
        }
      } else if (mode === "edit" && id) {
        const response = await putData(`${IMPORT_LC_TRACKER}/${id}`, formData);
        if (response) {
          message.success("Data updated successfully");
          navigate("/import-export-expense-tracking/import-lc-expense");
        }
      }
    } catch (error) {
      message.error("Failed to save data");
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "20px" }}
      onFinish={onFinish}
      disabled={mode === "view"}
      onValuesChange={calculateTotal}
    >
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item
            label="Sales Contract"
            name="sales_contract_id"
            rules={[
              { required: true, message: "Please select Sales Contract" },
            ]}
          >
            <Select
              placeholder="Select Sales Contract"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSearch={(value) => fetchSalesContracts({ reference_no: value })}
              onChange={handleSalesContractChange}
              disabled={mode === "view"}
            >
              {isArrayAndHasValue(options.salesContract) &&
                options.salesContract.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.reference_no}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Buyer"
            name="buyer_id"
            rules={[{ required: true, message: "Field is required!" }]}
          >
            <Select
              placeholder="Select Buyer"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={false}
              disabled
              defaultActiveFirstOption={true}
            >
              {isArrayAndHasValue(options.buyerInfo) &&
                options.buyerInfo.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="B2B LC No"
            name="btb_lc_no"
            rules={[{ required: true, message: "Please select B2B LC No" }]}
          >
            <Select
              placeholder="Select B2B LC No"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleB2BLcChange}
              disabled={mode === "view"}
            >
              {isArrayAndHasValue(options.b2bLcInfo) &&
                options.b2bLcInfo.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.b2b_lc_number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Supplier"
            name="supplier_id"
            rules={[{ required: true, message: "Field is required!" }]}
          >
            <Select
              placeholder="Select Supplier"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={false}
              disabled
            >
              {isArrayAndHasValue(options.supplierInfo) &&
                options.supplierInfo.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="LC Value"
            name="lc_value"
            rules={[{ required: true, message: "Field is required!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              disabled
              onChange={handleImportValueChange}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Amendment Charges (as per agreement) [BDT]"
            name="amendment_charges"
            rules={[{ required: true, message: "Field is required!" }]}
          >
            <InputNumber style={{ width: "100%" }} onChange={calculateTotal} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="LC stamp Charge@500/Per application [BDT]"
            name="lc_stamp_charges"
            initialValue={500}
            rules={[{ required: true, message: "Field is required!" }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={500}
              disabled
              onChange={calculateTotal}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Issue Commission (@.1%) [BDT] "
            name="issue_commission"
            tooltip="0.1% of Import Value (minimum 1000)"
            rules={[{ required: true, message: "Field is required!" }]}
          >
            <InputNumber style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Swift Charge (as per agreement) [BDT]"
            name="swift_charges"
            rules={[{ required: true, message: "Field is required!" }]}
          >
            <InputNumber style={{ width: "100%" }} onChange={calculateTotal} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Total"
            name="total"
            rules={[{ required: true, message: "Field is required!" }]}
          >
            <InputNumber style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ImportLcExpenseForm;
