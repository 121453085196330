import { Column } from "@ant-design/plots";
import { Card, Table } from "antd";
import { formatNumberWithCommas } from "utils/functions";

const UpToDate = ({ upToDateData }) => {
  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "line",
      key: "line",
    },
    {
      title: "Line Chief",
      dataIndex: "line_chief",
      key: "line_chief",
    },
    {
      title: "Line Attendance",
      dataIndex: "line_attendance",
      key: "line_attendance",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Up to date prod/pc",
      dataIndex: "today_production",
      key: "today_production",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Avg CM/USD",
      dataIndex: "avg_cm",
      key: "avg_cm",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Income CM/USD",
      dataIndex: "income_cm_usd",
      key: "income_cm_usd",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Income CM/Taka",
      dataIndex: "income_cm_taka",
      key: "income_cm_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Expenditure Taka",
      dataIndex: "expenditure_taka",
      key: "expenditure_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Up to date Est. Net Profit/taka",
      dataIndex: "net_profit",
      key: "net_profit",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Ranking on Profitability",
      dataIndex: "rank",
      key: "rank",
    },
  ];

  const config = {
    data: upToDateData?.barChart,
    xField: "line",
    yField: "profit",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };
  return (
    <Card
      title="Up To production, income & profit for Line wise:"
      bordered={false}
      style={{
        boxShadow: "none",
      }}
    >
      <Table
        dataSource={upToDateData?.data}
        columns={columns}
        pagination={false}
        bordered
      />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Up To Est. Net Profit/taka
        </h3>
        <Column {...config} />
      </div>
    </Card>
  );
};

export default UpToDate;
