import { useEffect, useState } from "react";

import { Button, Card, Col, DatePicker, Row, Typography } from "antd";
import { getData } from "apiServices/common";
import moment from "moment";
import ToDay from "./ToDay";
import UpToDate from "./UpToDate";

const SewingProfitLoss = () => {
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [todayData, setTodayData] = useState({
    data: [],
    barChart: [],
  });
  const [upToDateData, setUpToDateData] = useState({
    data: [],
    barChart: [],
  });

  const getDataForReport = async (firstDate, lastDate) => {
    try {
      const query = `/api/pnl-report/line-wise-sewing-profit-loss?start_date=${firstDate}&end_date=${lastDate}`;
      const res = await getData(query);
      return res.data?.data?.data?.map((item) => {
        const unique_line_chief = [
          ...new Set(item.line_chief.map((chief) => chief?.line_chief)),
        ].join(", ");
        const unique_buyer = [
          ...new Set(item.buyer.map((buyer) => buyer?.buyer_short_name)),
        ].join(", ");

        return {
          ...item,
          line_chief: unique_line_chief,
          buyer: unique_buyer,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // start_date and end_date are the same
        if (dateRange[0].isSame(dateRange[1], "day")) {
          const data = await getDataForReport(
            dateRange[0].format("YYYY-MM-DD"),
            dateRange[1].format("YYYY-MM-DD"),
          );

          const todayBarChart = data?.map((item) => {
            return {
              line: item.line,
              profit: parseFloat(item.net_profit).toFixed(2),
            };
          });

          setTodayData({
            data: data,
            barChart: todayBarChart,
          });
        }

        const data = await getDataForReport(
          dateRange[0].format("YYYY-MM-DD"),
          dateRange[1].format("YYYY-MM-DD"),
        );

        // UpToDate Data
        const upToDateBarChart = data?.map((item) => {
          return {
            line: item.line,
            profit: parseFloat(item.net_profit).toFixed(2),
          };
        });

        setUpToDateData({
          data: data,
          barChart: upToDateBarChart,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dateRange]);

  return (
    <Card>
      <Row>
        <Col span={24}>
          <Typography.Text>Choose Date Range: </Typography.Text>
        </Col>
        <Col span={24}>
          <DatePicker.RangePicker
            allowClear={false}
            onChange={(date, dateString) => {
              setDateRange(date);
            }}
            disabledDate={(current) => {
              if (!current) {
                return false;
              }
              const startOfMonth = moment().startOf("month");
              const endOfMonth = moment().endOf("month");
              return current < startOfMonth || current > endOfMonth;
            }}
            showTime={{ format: "YYYY-MM-DD" }}
            format="YYYY-MM-DD"
            defaultValue={[moment(), moment()]}
            value={dateRange}
            style={{
              marginRight: "10px",
            }}
          />
          <Button
            type="primary"
            danger
            onClick={() => setDateRange([moment(), moment()])}
          >
            Reset
          </Button>
          <br />
          <br />
        </Col>

        <Col span={24}>
          <ToDay todayData={todayData} />
        </Col>
        <Col span={24}>
          <br />
          <Typography.Title level={4}>C2Sta</Typography.Title>
          <UpToDate upToDateData={upToDateData} />
        </Col>
      </Row>
    </Card>
  );
};

export default SewingProfitLoss;
