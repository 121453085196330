// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SamJustification th.sam-justification {
  background-color: rgb(231, 231, 231);
  color: black;
}
.SamJustification th.production_per_hour {
  background-color: brown;
  color: white;
}

.SamJustification th.production_per_hour_ob {
  background-color: rgb(218, 149, 149) !important;
}
.SamJustification th.production_per_hour_actual {
  background-color: rgb(219, 101, 101) !important;
}

.SamJustification th.sam_data {
  background-color: darkblue;
  color: white;
}

.SamJustification th.sam_data_ob {
  background-color: rgb(149, 149, 218) !important;
}

.SamJustification th.sam_data_actual {
  background-color: rgb(101, 101, 219) !important;
}
.SamJustification th.sam_data_difference {
  background-color: rgb(211, 107, 10) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Digitalization/SamJustification/index.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,+CAA+C;AACjD;AACA;EACE,+CAA+C;AACjD;;AAEA;EACE,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,+CAA+C;AACjD;AACA;EACE,8CAA8C;AAChD","sourcesContent":[".SamJustification th.sam-justification {\n  background-color: rgb(231, 231, 231);\n  color: black;\n}\n.SamJustification th.production_per_hour {\n  background-color: brown;\n  color: white;\n}\n\n.SamJustification th.production_per_hour_ob {\n  background-color: rgb(218, 149, 149) !important;\n}\n.SamJustification th.production_per_hour_actual {\n  background-color: rgb(219, 101, 101) !important;\n}\n\n.SamJustification th.sam_data {\n  background-color: darkblue;\n  color: white;\n}\n\n.SamJustification th.sam_data_ob {\n  background-color: rgb(149, 149, 218) !important;\n}\n\n.SamJustification th.sam_data_actual {\n  background-color: rgb(101, 101, 219) !important;\n}\n.SamJustification th.sam_data_difference {\n  background-color: rgb(211, 107, 10) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
