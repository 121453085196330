// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-wise-hourly-defect thead .sl_no {
  background-color: rgb(143, 176, 255) !important;
}
.category-wise-hourly-defect thead .category_name {
  background-color: rgb(143, 176, 255) !important;
}

.category-wise-hourly-defect thead .hourly {
  background-color: rgb(101, 196, 109) !important;
}

.category-wise-hourly-defect thead .hour {
  background-color: rgb(124, 156, 126) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/AppDashboard/CategoryWiseHourlyDefect/style.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;AACjD;AACA;EACE,+CAA+C;AACjD;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":[".category-wise-hourly-defect thead .sl_no {\n  background-color: rgb(143, 176, 255) !important;\n}\n.category-wise-hourly-defect thead .category_name {\n  background-color: rgb(143, 176, 255) !important;\n}\n\n.category-wise-hourly-defect thead .hourly {\n  background-color: rgb(101, 196, 109) !important;\n}\n\n.category-wise-hourly-defect thead .hour {\n  background-color: rgb(124, 156, 126) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
