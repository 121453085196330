import { Column } from "@ant-design/plots";
import { Card, Table, Typography } from "antd";
import { formatNumberWithCommas } from "utils/functions";
const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Projected CM/Working Day (Taka)",
    dataIndex: "projectedCM",
    key: "projectedCM",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Actual CM/Working Day (Taka)",
    dataIndex: "actualCM",
    key: "actualCM",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Actual Cost/Working Day (Taka)",
    dataIndex: "actualCost",
    key: "actualCost",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Projected Cost (Taka)",
    dataIndex: "projectedCost",
    key: "projectedCost",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Projected Operating Profit/(Loss) TK",
    dataIndex: "projectedProfit",
    key: "projectedProfit",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Actual Operating Profit/(Loss) TK",
    dataIndex: "actualProfit",
    key: "actualProfit",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Variance Between Budgeted & Actual Profit",
    dataIndex: "variance",
    key: "variance",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Cumulative Projected Operating Profit/Loss TK",
    dataIndex: "cumProjectedProfit",
    key: "cumProjectedProfit",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
  {
    title: "Cumulative Operating Profit/Loss TK",
    dataIndex: "cumActualProfit",
    key: "cumActualProfit",
    align: "right",
    render: (value) => formatNumberWithCommas(parseFloat(value).toFixed(2)),
  },
];
const dataBar = [
  { line: "Line #01", profit: 5 },
  { line: "Line #02", profit: 10 },
  { line: "Line #03", profit: 15 },
  { line: "Line #04", profit: 20 },
  { line: "Line #05", profit: 25 },
  { line: "Line #06", profit: 30 },
  { line: "Line #07", profit: 35 },
  { line: "Line #08", profit: 40 },
  { line: "Line #09", profit: 45 },
  { line: "Line #10", profit: 50 },
  { line: "Line #11", profit: 55 },
  { line: "Line #12", profit: 60 },
  { line: "Line #13", profit: 65 },
  { line: "Line #14", profit: 70 },
  { line: "Line #15", profit: 75 },
  { line: "Line #16", profit: 80 },
  { line: "Line #17", profit: 85 },
  { line: "Line #18", profit: 90 },
  { line: "Line #19", profit: 95 },
  { line: "Line #20", profit: 100 },
  { line: "Line #21", profit: 105 },
  { line: "Line #22", profit: 110 },
  { line: "Line #23", profit: 115 },
  { line: "Line #24", profit: 120 },
  { line: "Line #25", profit: 125 },
  { line: "Line #26", profit: 130 },
  { line: "Line #27", profit: 135 },
  { line: "Line #28", profit: 140 },
  { line: "Line #29", profit: 145 },
  { line: "Line #30", profit: 150 },
  { line: "Line #31", profit: 155 },
  { line: "Line #32", profit: 160 },
  { line: "Line #33", profit: 165 },
  { line: "Line #34", profit: 170 },
  { line: "Line #35", profit: 175 },
  { line: "Line #36", profit: 180 },
  { line: "Line #37", profit: 185 },
  { line: "Line #38", profit: 190 },
];

const data = [
  {
    key: "1",
    date: "01-01-2021",
    projectedCM: "1000.90",
    actualCM: "900.90",
    actualCost: "800.90",
    projectedCost: "700.90",
    projectedProfit: "600.90",
    actualProfit: "500.90",
    variance: "400.90",
    cumProjectedProfit: "300.90525",
    cumActualProfit: "200.90525",
  },
  {
    key: "2",
    date: "02-01-2021",
    projectedCM: "1000.90",
    actualCM: "900.90",
    actualCost: "800.90",
    projectedCost: "700.90",
    projectedProfit: "600.90",
    actualProfit: "500.90",
    variance: "400.90",
    cumProjectedProfit: "300.90525",
    cumActualProfit: "200.90525",
  },
  {
    key: "3",
    date: "03-01-2021",
    projectedCM: "1000.90",
    actualCM: "900.90",
    actualCost: "800.90",
    projectedCost: "700.90",
    projectedProfit: "600.90",
    actualProfit: "500.90",
    variance: "400.90",
    cumProjectedProfit: "300.90525",
    cumActualProfit: "200.90525",
  },
];

const DailyProfitLossSummary = () => {
  const config = {
    data: dataBar,
    xField: "line",
    yField: "profit",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };
  return (
    <Card title="Daily Profit & Loss Summary">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        summary={(data) => {
          let totalProjectedCM = 0;
          let totalActualCM = 0;
          let totalActualCost = 0;
          let totalProjectedCost = 0;
          let totalProjectedProfit = 0;
          let totalActualProfit = 0;
          let totalVariance = 0;
          let totalCumProjectedProfit = 0;
          let totalCumActualProfit = 0;
          data.forEach(
            ({
              projectedCM,
              actualCM,
              actualCost,
              projectedCost,
              projectedProfit,
              actualProfit,
              variance,
              cumProjectedProfit,
              cumActualProfit,
            }) => {
              totalProjectedCM += parseFloat(projectedCM);
              totalActualCM += parseFloat(actualCM);
              totalActualCost += parseFloat(actualCost);
              totalProjectedCost += parseFloat(projectedCost);
              totalProjectedProfit += parseFloat(projectedProfit);
              totalActualProfit += parseFloat(actualProfit);
              totalVariance += parseFloat(variance);
              totalCumProjectedProfit += parseFloat(cumProjectedProfit);
              totalCumActualProfit += parseFloat(cumActualProfit);
            },
          );

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Typography.Text strong>Total</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalProjectedCM.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalActualCM.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalActualCost.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalProjectedCost.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalProjectedProfit.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalActualProfit.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalVariance.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalCumProjectedProfit.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} align="right">
                  <Typography.Text strong>
                    {formatNumberWithCommas(totalCumActualProfit.toFixed(2))}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <br />
      {/* Daily Profit & Loss Summary of CGL (Projected vs Actual) */}
      <Typography.Text strong>
        Daily Profit & Loss Summary of CGL (Projected vs Actual)
      </Typography.Text>
      <Column {...config} />
      <br />
      {/* Cumulative Profit & Loss Summary of CGL (Projected vs Actual) */}
      <Typography.Text strong>
        Cumulative Profit & Loss Summary of CGL (Projected vs Actual)
      </Typography.Text>
      <Column {...config} />
    </Card>
  );
};

export default DailyProfitLossSummary;
