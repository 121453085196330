import { Col, Form, Input, InputNumber, message, Row, Select } from "antd";
import {
  EXPORT_INVOICE_TRACKER,
  GET_SALES_CONTRACT_LIST,
} from "apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "apiServices/common";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { isArrayAndHasValue } from "utils/functions";

const { Option } = Select;

const InvoiceForm = ({ form, mode = "create" }) => {
  const [options, setOptions] = useState({
    salesContract: [],
    invoiceNumber: [],
  });

  const { exportInvoiceExpenseId } = useParams();
  const navigate = useNavigate();

  // form watch values
  const co_fee = Form.useWatch("co_fee", form);
  const bl_fee = Form.useWatch("bl_fee", form);
  const bl_surrender_fee = Form.useWatch("bl_surrender_fee", form);
  const export_cndf_cost = Form.useWatch("export_cndf_cost", form);
  const courier_cost = Form.useWatch("courier_cost", form);
  const export_transport_cost = Form.useWatch("export_transport_cost", form);

  const onFinish = async (values) => {
    try {
      const body = {
        ...values,
        invoice_value: Number(values.invoice_value).toFixed(2),
        source_tax: Number(values.source_tax).toFixed(2),
        central_bank_fund: Number(values.central_bank_fund).toFixed(2),
        co_fee: Number(values.co_fee).toFixed(2),
        bl_fee: Number(values.bl_fee).toFixed(2),
        bl_surrender_fee: Number(values.bl_surrender_fee).toFixed(2),
        export_cndf_cost: Number(values.export_cndf_cost).toFixed(2),
        courier_cost: Number(values.courier_cost).toFixed(2),
        export_transport_cost: Number(values.export_transport_cost).toFixed(2),
      };

      // console.log("Final body: ", body);
      // Call API to save data
      if (mode === "update" && exportInvoiceExpenseId) {
        await putData(
          `${EXPORT_INVOICE_TRACKER}/${exportInvoiceExpenseId}`,
          body,
        );
      } else {
        await postData(EXPORT_INVOICE_TRACKER, body);
      }
      navigate("/import-export-expense-tracking/export-invoice-expense");
      message.success("Data saved successfully");
    } catch (error) {
      console.error("Error saving data: ", error);
      message.error("Failed to save data");
    }
  };

  const getAllPermitList = useCallback(async (passedObject) => {
    const filter = passedObject;
    const customQuery = `${GET_SALES_CONTRACT_LIST}?page=${1}&per_page=${20}`;
    const res = await getData(customQuery, false, filter);

    if (res) {
      let masterData = res?.data;
      setOptions((prev) => ({
        ...prev,
        salesContract: masterData?.data?.data,
      }));
    }
  }, []);

  const onChangeSalesContract = async (value) => {
    if (!value) {
      return;
    }
    // /api/export-expense/sales-contract/55
    const customQuery = `/api/export-expense/sales-contract/${value}`;
    const res = await getData(customQuery, false, {});
    if (res) {
      let masterData = res?.data;
      console.log("masterData", masterData);
      setOptions((prev) => ({
        ...prev,
        invoiceNumber: masterData?.export_details,
      }));

      form.setFieldsValue({
        buyer_id: masterData?.buyer?.id,
        buyer_name: masterData?.buyer?.name,
        order_qty: masterData?.sales_contract_commercial?.order_quantity,
      });
    }
  };

  const totalCalculation = () => {
    const invoiceValue = form.getFieldValue("invoice_value");
    const sourceTax = form.getFieldValue("source_tax");
    const centralBankFund = form.getFieldValue("central_bank_fund");
    const coFee = co_fee || 0;
    const blFee = bl_fee || 0;
    const blSurrenderFee = bl_surrender_fee || 0;
    const exportCndfCost = export_cndf_cost || 0;
    const courierCost = courier_cost || 0;
    const exportTransportCost = export_transport_cost || 0;
    const expCost = form.getFieldValue("exp_cost");
    const total =
      Number(invoiceValue) +
      Number(sourceTax) +
      Number(centralBankFund) +
      Number(coFee) +
      Number(blFee) +
      Number(blSurrenderFee) +
      Number(exportCndfCost) +
      Number(courierCost) +
      Number(exportTransportCost) +
      Number(expCost);
    form.setFieldsValue({
      total: total.toFixed(2),
    });
  };

  const onInvoiceNumberChange = (value) => {
    const selectedInvoice = options.invoiceNumber.find(
      (item) => item.invoice_number === value,
    );
    if (!selectedInvoice) return;
    const invoiceValue = Number(selectedInvoice?.invoice_value).toFixed(2);
    form.setFieldsValue({
      invoice_value: invoiceValue,
      source_tax: Number(invoiceValue * 0.01).toFixed(2),
      central_bank_fund: Number(invoiceValue * 0.0003).toFixed(2),
    });
    totalCalculation();
  };

  useEffect(() => {
    if (exportInvoiceExpenseId) {
      const customQuery = `${EXPORT_INVOICE_TRACKER}/${exportInvoiceExpenseId}/edit`;
      getData(customQuery, false, {}).then((res) => {
        if (res) {
          let masterData = res?.data?.data;
          onChangeSalesContract(masterData?.sales_contract_id);
          form.setFieldsValue({
            sales_contract_id: masterData?.sales_contract_id,
            buyer_id: masterData?.buyer?.id,
            buyer_name: masterData?.buyer?.name,
            order_qty: masterData?.sales_contract_commercial?.order_quantity,
            invoice_number: masterData?.invoice_number,
            invoice_value: Number(masterData?.invoice_value).toFixed(2),
            source_tax: Number(masterData?.source_tax).toFixed(2),
            central_bank_fund: Number(masterData?.central_bank_fund).toFixed(2),
            co_fee: Number(masterData?.co_fee).toFixed(2),
            bl_fee: Number(masterData?.bl_fee).toFixed(2),
            bl_surrender_fee: Number(masterData?.bl_surrender_fee).toFixed(2),
            export_cndf_cost: Number(masterData?.export_cndf_cost).toFixed(2),
            courier_cost: Number(masterData?.courier_cost).toFixed(2),
            export_transport_cost: Number(
              masterData?.export_transport_cost,
            ).toFixed(2),
            exp_cost: masterData?.exp_cost,
            total: Number(masterData?.total).toFixed(2),
          });
        }
      });
    }
    getAllPermitList();
    form.setFieldsValue({
      exp_cost: 100,
      co_fee: 0,
      bl_fee: 0,
      bl_surrender_fee: 0,
      export_cndf_cost: 0,
      courier_cost: 0,
      export_transport_cost: 0,
      source_tax: 0,
      central_bank_fund: 0,
      total: 0,
      invoice_value: 0,
    });
  }, []);

  totalCalculation();

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "20px" }}
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={4}>
          <Form.Item
            label="Sales Contract"
            name="sales_contract_id"
            rules={[
              { required: true, message: "Please select Sales Contract" },
            ]}
          >
            <Select
              disabled={mode === "view"}
              placeholder="Select Sales Contract"
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={false}
              onSearch={(value) => getAllPermitList({ reference_no: value })}
              onChange={(value) => onChangeSalesContract(value)}
              onClear={() => {
                setOptions((prev) => ({
                  ...prev,
                  invoiceNumber: [],
                }));
                form.setFieldsValue({
                  invoice_number: undefined,
                  invoice_value: 0,
                  source_tax: 0,
                  central_bank_fund: 0,
                  buyer_id: "",
                  buyer_name: "",
                  order_qty: 0,
                });
              }}
            >
              {isArrayAndHasValue(options.salesContract) &&
                options.salesContract.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.reference_no}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="buyer_id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item label="Buyer" name="buyer_name">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Order Qty (pc)" name="order_qty">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Invoice Number"
            name="invoice_number"
            rules={[
              { required: true, message: "Please select Invoice Number" },
            ]}
          >
            <Select
              disabled={mode === "view"}
              placeholder="Select Invoice Number"
              onChange={onInvoiceNumberChange}
            >
              {isArrayAndHasValue(options.invoiceNumber) &&
                options.invoiceNumber.map((item) => (
                  <Option key={item.invoice_number} value={item.invoice_number}>
                    {item.invoice_number}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Invoice Value" name="invoice_value">
            <InputNumber disabled style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="CO Fee"
            name="co_fee"
            rules={[{ required: true, message: "Please enter CO Fee" }]}
          >
            <InputNumber style={{ width: "100%" }} disabled={mode === "view"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="BL Fee"
            name="bl_fee"
            rules={[{ required: true, message: "Please enter BL Fee" }]}
          >
            <InputNumber style={{ width: "100%" }} disabled={mode === "view"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="BL Surrender Fee"
            name="bl_surrender_fee"
            rules={[
              { required: true, message: "Please enter BL Surrender Fee" },
            ]}
          >
            <InputNumber style={{ width: "100%" }} disabled={mode === "view"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="C&F COST PAID (EXPORT)"
            name="export_cndf_cost"
            rules={[
              {
                required: true,
                message: "Please enter C&F COST PAID (EXPORT)",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} disabled={mode === "view"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="COURIER COST"
            name="courier_cost"
            rules={[{ required: true, message: "Please enter COURIER COST" }]}
          >
            <InputNumber style={{ width: "100%" }} disabled={mode === "view"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="TRANSPORT COST (EXPORT)"
            name="export_transport_cost"
            rules={[
              {
                required: true,
                message: "Please enter TRANSPORT COST (EXPORT)",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} disabled={mode === "view"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="EXP Cost" name="exp_cost">
            <InputNumber style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="SOURCE TAX @1%" name="source_tax">
            <InputNumber style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="CENTRAL BANK FUND @0.03%" name="central_bank_fund">
            <InputNumber style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Total" name="total">
            <InputNumber style={{ width: "100%" }} disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default InvoiceForm;
