import { Column } from "@ant-design/plots";
import { Button, Card, Col, DatePicker, Row, Space, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { formatNumberWithCommas } from "utils/functions";

export default function TargetVsAchievement() {
  const [selectedDate, setSelectedDate] = useState(moment());
  // const [data, setData] = useState({
  //   dataSource: [],
  //   loading: false,
  // });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const data = [
    { line: "Line #01", profit: 5 },
    { line: "Line #02", profit: 10 },
    { line: "Line #03", profit: 15 },
    { line: "Line #04", profit: 20 },
    { line: "Line #05", profit: 25 },
    { line: "Line #06", profit: 30 },
    { line: "Line #07", profit: 35 },
    { line: "Line #08", profit: 40 },
    { line: "Line #09", profit: 45 },
    { line: "Line #10", profit: 50 },
    { line: "Line #11", profit: 55 },
    { line: "Line #12", profit: 60 },
    { line: "Line #13", profit: 65 },
    { line: "Line #14", profit: 70 },
    { line: "Line #15", profit: 75 },
    { line: "Line #16", profit: 80 },
    { line: "Line #17", profit: 85 },
    { line: "Line #18", profit: 90 },
    { line: "Line #19", profit: 95 },
    { line: "Line #20", profit: 100 },
    { line: "Line #21", profit: 105 },
    { line: "Line #22", profit: 110 },
    { line: "Line #23", profit: 115 },
    { line: "Line #24", profit: 120 },
    { line: "Line #25", profit: 125 },
    { line: "Line #26", profit: 130 },
    { line: "Line #27", profit: 135 },
    { line: "Line #28", profit: 140 },
    { line: "Line #29", profit: 145 },
    { line: "Line #30", profit: 150 },
    { line: "Line #31", profit: 155 },
    { line: "Line #32", profit: 160 },
    { line: "Line #33", profit: 165 },
    { line: "Line #34", profit: 170 },
    { line: "Line #35", profit: 175 },
    { line: "Line #36", profit: 180 },
    { line: "Line #37", profit: 185 },
    { line: "Line #38", profit: 190 },
  ];

  const config = {
    data,
    xField: "line",
    yField: "profit",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  const dataSource = [
    {
      sewingLine: "Line #01",
      lineChief: "Mr. A",
      buyer: "Buyer #01",
      requiredQuantityForBEven: 1000,
      ieGivenTarget: 1050,
      ieTargetPercentageOfBEQty: 105,
      actualProduction: 1000,
      differenceFromBreakeven: 0,
      differenceFromIETarget: 50,
      percentageOfAchievementFromIETarget: 95,
      percentageOfAchievementFromBEQty: 100,
      rankingOnAchievementFromBE: 1,
    },
    {
      sewingLine: "Line #02",
      lineChief: "Mr. B",
      buyer: "Buyer #02",
      requiredQuantityForBEven: 2000,
      ieGivenTarget: 2100,
      ieTargetPercentageOfBEQty: 105,
      actualProduction: 2000,
      differenceFromBreakeven: 56,
      differenceFromIETarget: 100,
      percentageOfAchievementFromIETarget: 95,
      percentageOfAchievementFromBEQty: 100,
      rankingOnAchievementFromBE: 2,
    },
    {
      sewingLine: "Line #03",
      lineChief: "Mr. C",
      buyer: "Buyer #03",
      requiredQuantityForBEven: 3000,
      ieGivenTarget: 3150,
      ieTargetPercentageOfBEQty: 105,
      actualProduction: 3000,
      differenceFromBreakeven: 0,
      differenceFromIETarget: 150,
      percentageOfAchievementFromIETarget: 95,
      percentageOfAchievementFromBEQty: 100,
      rankingOnAchievementFromBE: 3,
    },
    {
      sewingLine: "Line #04",
      lineChief: "Mr. D",
      buyer: "Buyer #04",
      requiredQuantityForBEven: 4000,
      ieGivenTarget: 4200,
      ieTargetPercentageOfBEQty: 105,
      actualProduction: 4000,
      differenceFromBreakeven: 20,
      differenceFromIETarget: 200,
      percentageOfAchievementFromIETarget: 95,
      percentageOfAchievementFromBEQty: 100,
      rankingOnAchievementFromBE: 4,
    },
    {
      sewingLine: "Line #05",
      lineChief: "Mr. E",
      buyer: "Buyer #05",
      requiredQuantityForBEven: 5000,
      ieGivenTarget: 5250,
      ieTargetPercentageOfBEQty: 105,
      actualProduction: 5000,
      differenceFromBreakeven: 10,
      differenceFromIETarget: 250,
      percentageOfAchievementFromIETarget: 95,
      percentageOfAchievementFromBEQty: 100,
      rankingOnAchievementFromBE: 5,
    },
  ];

  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "sewingLine",
      key: "sewingLine",
    },
    {
      title: "Line Chief",
      dataIndex: "lineChief",
      key: "lineChief",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
    },
    {
      title: "Required quantity for B/Even",
      dataIndex: "requiredQuantityForBEven",
      key: "requiredQuantityForBEven",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "IE given Target",
      dataIndex: "ieGivenTarget",
      key: "ieGivenTarget",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "IE Target % of B/E Qty",
      dataIndex: "ieTargetPercentageOfBEQty",
      key: "ieTargetPercentageOfBEQty",
      align: "right",
      render: (text) => `${text}%`,
    },
    {
      title: "Actual Production",
      dataIndex: "actualProduction",
      key: "actualProduction",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Difference from Breakeven",
      dataIndex: "differenceFromBreakeven",
      key: "differenceFromBreakeven",
      align: "right",
      render: (text) => `(${formatNumberWithCommas(text)})`,
    },
    {
      title: "Difference from IE Target",
      dataIndex: "differenceFromIETarget",
      key: "differenceFromIETarget",
      align: "right",
      render: (text) => `(${formatNumberWithCommas(text)})`,
    },
    {
      title: "% of Achievement from IE target",
      dataIndex: "percentageOfAchievementFromIETarget",
      key: "percentageOfAchievementFromIETarget",
      align: "right",
      render: (text) => `${text}%`,
    },
    {
      title: "% of Achievement from B/E req qty",
      dataIndex: "percentageOfAchievementFromBEQty",
      key: "percentageOfAchievementFromBEQty",
      align: "right",
      render: (text) => `${text}%`,
    },
    {
      title: "Ranking on Achievement from B/E",
      dataIndex: "rankingOnAchievementFromBE",
      key: "rankingOnAchievementFromBE",
      align: "right",
    },
  ];

  return (
    <Card
      title={`
        Line wise achivement & Target details as on ${selectedDate.format(
          "DD-MMM-YYYY",
        )}
      `}
      bordered={false}
    >
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-YYYY"}
            style={{
              width: "100%",
            }}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={3}>
          <Space>
            <Button
              type="primary"
              danger
              onClick={() => setSelectedDate(moment())}
            >
              Reset
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        size="small"
        summary={(pageData) => {
          let totalRequiredQuantityForBEven = 0;
          let totalIeGivenTarget = 0;
          let totalIeTargetPercentageOfBEQty = 0;
          let totalActualProduction = 0;
          let totalDifferenceFromBreakeven = 0;
          let totalDifferenceFromIETarget = 0;
          let totalPercentageOfAchievementFromIETarget = 0;
          let totalPercentageOfAchievementFromBEQty = 0;

          pageData.forEach(
            ({
              requiredQuantityForBEven,
              ieGivenTarget,
              ieTargetPercentageOfBEQty,
              actualProduction,
              differenceFromBreakeven,
              differenceFromIETarget,
              percentageOfAchievementFromIETarget,
              percentageOfAchievementFromBEQty,
            }) => {
              totalRequiredQuantityForBEven += requiredQuantityForBEven;
              totalIeGivenTarget += ieGivenTarget;
              totalIeTargetPercentageOfBEQty += ieTargetPercentageOfBEQty;
              totalActualProduction += actualProduction;
              totalDifferenceFromBreakeven += differenceFromBreakeven;
              totalDifferenceFromIETarget += differenceFromIETarget;
              totalPercentageOfAchievementFromIETarget +=
                percentageOfAchievementFromIETarget;
              totalPercentageOfAchievementFromBEQty +=
                percentageOfAchievementFromBEQty;
            },
          );

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>Total</Table.Summary.Cell>

                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalRequiredQuantityForBEven)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalIeGivenTarget)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    {formatNumberWithCommas(totalIeTargetPercentageOfBEQty)}%
                  </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalActualProduction)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalDifferenceFromBreakeven)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>{formatNumberWithCommas(totalDifferenceFromIETarget)}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    {(
                      totalPercentageOfAchievementFromIETarget / pageData.length
                    ).toFixed(2)}
                    %
                  </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right">
                  <b>
                    {(
                      totalPercentageOfAchievementFromBEQty / pageData.length
                    ).toFixed(2)}
                    %
                  </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Column {...config} />
      </div>
    </Card>
  );
}
