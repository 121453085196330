import { Column } from "@ant-design/plots";
import { Button, Card, Col, DatePicker, Row, Space, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { formatNumberWithCommas } from "utils/functions";

export default function CPM() {
  const [selectedDate, setSelectedDate] = useState(moment());
  // const [data, setData] = useState({
  //   dataSource: [],
  //   loading: false,
  // });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const data = [
    { line: "Line #01", profit: 5 },
    { line: "Line #02", profit: 10 },
    { line: "Line #03", profit: 15 },
    { line: "Line #04", profit: 20 },
    { line: "Line #05", profit: 25 },
    { line: "Line #06", profit: 30 },
    { line: "Line #07", profit: 35 },
    { line: "Line #08", profit: 40 },
    { line: "Line #09", profit: 45 },
    { line: "Line #10", profit: 50 },
    { line: "Line #11", profit: 55 },
    { line: "Line #12", profit: 60 },
    { line: "Line #13", profit: 65 },
    { line: "Line #14", profit: 70 },
    { line: "Line #15", profit: 75 },
    { line: "Line #16", profit: 80 },
    { line: "Line #17", profit: 85 },
    { line: "Line #18", profit: 90 },
    { line: "Line #19", profit: 95 },
    { line: "Line #20", profit: 100 },
    { line: "Line #21", profit: 105 },
    { line: "Line #22", profit: 110 },
    { line: "Line #23", profit: 115 },
    { line: "Line #24", profit: 120 },
    { line: "Line #25", profit: 125 },
    { line: "Line #26", profit: 130 },
    { line: "Line #27", profit: 135 },
    { line: "Line #28", profit: 140 },
    { line: "Line #29", profit: 145 },
    { line: "Line #30", profit: 150 },
    { line: "Line #31", profit: 155 },
    { line: "Line #32", profit: 160 },
    { line: "Line #33", profit: 165 },
    { line: "Line #34", profit: 170 },
    { line: "Line #35", profit: 175 },
    { line: "Line #36", profit: 180 },
    { line: "Line #37", profit: 185 },
    { line: "Line #38", profit: 190 },
  ];

  const config = {
    data,
    xField: "line",
    yField: "profit",
    label: {
      text: (originData) => {
        // const val = parseFloat(originData.profit);
        // if (val < 0.05) {
        //   return (val * 100).toFixed(1) + "%";
        // }
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  const dataSource = [
    {
      sewingLine: "Line #01",
      lineChief: "RAFIQUL ISLAM",
      lineAttendance: 77,
      cost: 1522644,
      production: 1230,
      sam: 19.85,
      earnMinutes: 24420,
      cpm: 0.071,
      rankingOnCPM: "15th",
    },
    {
      sewingLine: "Line #02",
      lineChief: "SHERAJ (ACT)",
      lineAttendance: 71,
      cost: 1522644,
      production: 1670,
      sam: 21.69,
      earnMinutes: 36222,
      cpm: 0.045,
      rankingOnCPM: "8th",
    },
    {
      sewingLine: "Line #03",
      lineChief: "FIROZ",
      lineAttendance: 74,
      cost: 1522644,
      production: 1250,
      sam: 30.64,
      earnMinutes: 38300,
      cpm: 0.043,
      rankingOnCPM: "7th",
    },
    {
      sewingLine: "Line #04",
      lineChief: "SHAHADAT",
      lineAttendance: 81,
      cost: 1522644,
      production: 1350,
      sam: 30.64,
      earnMinutes: 41364,
      cpm: 0.043,
      rankingOnCPM: "6th",
    },
    {
      sewingLine: "Line #05",
      lineChief: "SOBUJ",
      lineAttendance: 69,
      cost: 1522644,
      production: 1000,
      sam: 21.7,
      earnMinutes: 21696,
      cpm: 0.074,
      rankingOnCPM: "16th",
    },
    {
      sewingLine: "Line #06",
      lineChief: "SHAHIN",
      lineAttendance: 70,
      cost: 1522644,
      production: 1430,
      sam: 22.17,
      earnMinutes: 31703,
      cpm: 0.051,
      rankingOnCPM: "10th",
    },
  ];

  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "sewingLine",
      key: "sewingLine",
    },
    {
      title: "Line Chief",
      dataIndex: "lineChief",
      key: "lineChief",
    },
    {
      title: "Line Attendance",
      dataIndex: "lineAttendance",
      key: "lineAttendance",
      align: "right",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (value) => formatNumberWithCommas(value.toFixed(2)),
      align: "right",
    },
    {
      title: "Production",
      dataIndex: "production",
      key: "production",
      align: "right",
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "SAM",
      dataIndex: "sam",
      key: "sam",
      align: "right",
      render: (value) => formatNumberWithCommas(value.toFixed(2)),
    },
    {
      title: "Earn Minutes",
      dataIndex: "earnMinutes",
      key: "earnMinutes",
      align: "right",
      render: (value) => formatNumberWithCommas(value),
    },
    {
      title: "CPM",
      dataIndex: "cpm",
      key: "cpm",
      align: "right",
      render: (value) => value.toFixed(3),
    },
    {
      title: "Ranking on CPM",
      dataIndex: "rankingOnCPM",
      key: "rankingOnCPM",
    },
  ];

  return (
    <Card
      title={`
        Line wise Daily CPM Report as on ${selectedDate.format("DD-MMM-YYYY")}
      `}
      bordered={false}
    >
      <br />
      <Row gutter={24}>
        <Col span={6}>
          <DatePicker
            allowClear={false}
            format={"DD-MMM-YYYY"}
            style={{
              width: "100%",
            }}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={3}>
          <Space>
            <Button
              type="primary"
              danger
              onClick={() => setSelectedDate(moment())}
            >
              Reset
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        summary={(tableData) => {
          let totalCost = 0;
          let totalProduction = 0;
          let totalEarnMinutes = 0;
          let totalCPM = 0;
          tableData.forEach(({ cost, production, earnMinutes, cpm }) => {
            totalCost += parseFloat(cost);
            totalProduction += parseFloat(production);
            totalEarnMinutes += parseFloat(earnMinutes);
            totalCPM += parseFloat(cpm);
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="right">
                {formatNumberWithCommas(totalCost.toFixed(2))}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="right">
                {totalProduction}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6} align="right">
                {totalEarnMinutes}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} align="right">
                {totalCPM.toFixed(3)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Column {...config} />
      </div>
    </Card>
  );
}
