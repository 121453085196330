// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation-against-defects thead .name {
  background-color: rgb(101, 196, 109) !important;
}
.recommendation-against-defects thead .value {
  background-color: rgb(101, 196, 109) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/AppDashboard/RecommendationAgainstDefects/style.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;AACjD;AACA;EACE,+CAA+C;AACjD","sourcesContent":[".recommendation-against-defects thead .name {\n  background-color: rgb(101, 196, 109) !important;\n}\n.recommendation-against-defects thead .value {\n  background-color: rgb(101, 196, 109) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
