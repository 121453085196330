import { Affix, Card, Col, Row, Skeleton, Spin, Typography } from "antd";
import { getData } from "apiServices/common";
import { Fragment, useEffect, useState } from "react";
import { isMobileView } from "../../Common/AppHelper";
import Header from "../../Common/Header";

import Calendar from "./Calendar";
import Filter from "./Filter";
import HeaderDescription from "./HeaderDescription";
import "./index.css";
import { res, transformData } from "./Calendar/utils";
import dayjs from "dayjs";

const ProductionPlanningDashboard2 = (props) => {
  const [date, setDate] = useState(dayjs().format("YYYY-MM"));
  const [calendarData, setCalendarData] = useState(null);

  // Props
  const { width } = props;

  const startDate = dayjs(date, "YYYY-MM")
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = dayjs(date, "YYYY-MM").endOf("month").format("YYYY-MM-DD");

  // console.log("date", date);
  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  const init = async () => {
    setCalendarData(null);
    // Initialize /api/productionPlanning/dashboard

    // const urlForWeekendList = `/api/productionPlanning/weekend/weekend-list?startDate=${startDate}&endDate=${endDate}`;
    // const urlForHolidayList = `/api/productionPlanning/holiday/holiday-list?startDate=${startDate}&endDate=${endDate}`;
    // const urlForExceptionList = `/api/productionPlanning/weekend-holiday-exception/exceptions?startDate=${startDate}&endDate=${endDate}`;
    const urlForPlanInitiateList = `/api/productionPlanning/plan_initiate/list?startDate=${startDate}&endDate=${endDate}`;
    const urlForCalendarHeader = `/api/productionPlanning/weekend/calendar-header?startDate=${startDate}&endDate=${endDate}`;

    // const responseWeekendList = await getData(urlForWeekendList);
    // const responseHolidayList = await getData(urlForHolidayList);
    // const responseExceptionList = await getData(urlForExceptionList);
    const responseForCalendarHeader = await getData(urlForCalendarHeader);
    const responsePlanInitiateList = await getData(urlForPlanInitiateList);

    if (
      responsePlanInitiateList &&
      responsePlanInitiateList.data?.code === 200 &&
      responsePlanInitiateList.data?.data?.length > 0
    ) {
      const planInitiateList = responsePlanInitiateList.data.data;
      // const holidayList = responseHolidayList.data.data;
      // const weekendList = responseWeekendList.data.data;
      // const exceptionList = responseExceptionList.data.data;
      const calendarHeader = responseForCalendarHeader.data.data;

      const data = transformData(
        // res.data,
        planInitiateList,
        startDate,
        calendarHeader,
      );

      setCalendarData(data);
    }
  };

  useEffect(() => {
    init();
  }, [date]);

  console.log("calendarData", calendarData);

  return (
    <Fragment>
      <Affix offsetTop={0}>
        <Card
          className="p-fixed"
          style={{ width: `${isMobileView() ? "100%" : `${width}px`}` }}
        >
          <Header
            title={
              <Typography.Title level={5}>
                Production Planning Dashboard
              </Typography.Title>
            }
          />
        </Card>
      </Affix>

      <Card
        className="production-planning-dashboard"
        style={{
          padding: "0px 10px",
        }}
      >
        <Row gutter={24}>
          <Col span={16}>
            <HeaderDescription />
          </Col>
          <Col span={8}>
            <Filter setDate={setDate} />
          </Col>
          <Col span={24}>
            <div
              style={{
                width: "100%",
                overflowX: "auto", // Enable horizontal scrolling
                whiteSpace: "nowrap", // Prevent content from wrapping
              }}
            >
              <div
                style={{
                  minWidth: "1500px", // Ensure content is wider than the container
                }}
              >
                {calendarData ? (
                  <Calendar
                    calendarData={calendarData}
                    setCalendarData={setCalendarData}
                    refetch={init}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <Spin />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default ProductionPlanningDashboard2;

export const cardStyle = {
  marginTop: "10px",
  borderRadius: "10px",
  padding: "10px",
  height: "100%",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
};
