import {
  AppstoreAddOutlined,
  BellOutlined,
  CarryOutOutlined,
  CiOutlined,
  CloseSquareOutlined,
  ClusterOutlined,
  DeliveredProcedureOutlined,
  DiffOutlined,
  ExportOutlined,
  FileDoneOutlined,
  HomeOutlined,
  ImportOutlined,
  LoginOutlined,
  PoundOutlined,
  RadiusSettingOutlined,
  RobotOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  SkinOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  ACCOUNT_CREATE_PERMISSION,
  ACCOUNT_DELETE_PERMISSION,
  ACCOUNT_EDIT_PERMISSION,
  ACCOUNT_VIEW_PERMISSION,
  ACTIVITY_LOG_VIEW_PERMISSION,
  APP_DASHBOARD_VIEW_PERMISSION,
  ASSIGNMENT_CREATE_PERMISSION,
  ASSIGNMENT_DELETE_PERMISSION,
  ASSIGNMENT_EDIT_PERMISSION,
  ASSIGNMENT_VIEW_PERMISSION,
  B2BLC_CREATE_PERMISSION,
  B2BLC_DELETE_PERMISSION,
  B2BLC_EDIT_PERMISSION,
  B2BLC_VIEW_PERMISSION,
  BANK_CREATE_PERMISSION,
  BANK_DELETE_PERMISSION,
  BANK_EDIT_PERMISSION,
  BANK_VIEW_PERMISSION,
  BIN_CREATE_PERMISSION,
  BIN_DELETE_PERMISSION,
  BIN_EDIT_PERMISSION,
  BIN_VIEW_PERMISSION,
  BOLT_SEAL_LOG_CREATE_PERMISSION,
  BOLT_SEAL_LOG_DELETE_PERMISSION,
  BOLT_SEAL_LOG_EDIT_PERMISSION,
  BOLT_SEAL_LOG_REPORT_PERMISSION,
  BOLT_SEAL_LOG_VIEW_PERMISSION,
  BOM_CREATE_PERMISSION,
  BOM_DELETE_PERMISSION,
  BOM_EDIT_PERMISSION,
  BOM_EXPORT_PERMISSION,
  BOM_VIEW_PERMISSION,
  BRANCH_CREATE_PERMISSION,
  BRANCH_DELETE_PERMISSION,
  BRANCH_EDIT_PERMISSION,
  BRANCH_VIEW_PERMISSION,
  BUYER_CREATE_PERMISSION,
  BUYER_DELETE_PERMISSION,
  BUYER_EDIT_PERMISSION,
  BUYER_INQUIRY_CREATE_PERMISSION,
  BUYER_INQUIRY_DELETE_PERMISSION,
  BUYER_INQUIRY_EDIT_PERMISSION,
  BUYER_INQUIRY_VIEW_PERMISSION,
  BUYER_VIEW_PERMISSION,
  BUYER_WISE_CHECKLIST_VIEW_PERMISSION,
  BUYING_OFFICE_CREATE_PERMISSION,
  BUYING_OFFICE_DELETE_PERMISSION,
  BUYING_OFFICE_EDIT_PERMISSION,
  BUYING_OFFICE_VIEW_PERMISSION,
  CAPACITY_BOOKING_MENU_VIEW_PERMISSION,
  CHALLAN_CREATE_PERMISSION,
  CHALLAN_DELETE_PERMISSION,
  CHALLAN_EDIT_PERMISSION,
  CHALLAN_VIEW_PERMISSION,
  CHECKLIST_MENU_VIEW_PERMISSION,
  COLOR_CREATE_PERMISSION,
  COLOR_DELETE_PERMISSION,
  COLOR_EDIT_PERMISSION,
  COLOR_VIEW_PERMISSION,
  COST_ESTIMATE_CREATE_PERMISSION,
  COST_ESTIMATE_DELETE_PERMISSION,
  COST_ESTIMATE_EDIT_PERMISSION,
  COST_ESTIMATE_VIEW_PERMISSION,
  COST_OF_MAKING_CREATE,
  COST_OF_MAKING_DELETE,
  COST_OF_MAKING_EDIT,
  COST_OF_MAKING_LIST,
  COST_SHEET_REPORT_DELETE_PERMISSION,
  COST_SHEET_REPORT_GENERATE_PERMISSION,
  COST_SHEET_REPORT_VIEW_PERMISSION,
  CUTTING_CREATE_PERMISSION,
  CUTTING_DELETE_PERMISSION,
  CUTTING_EDIT_PERMISSION,
  CUTTING_VIEW_PERMISSION,
  DAILY_IMPORT_POSITION_CREATE_PERMISSION,
  DAILY_IMPORT_POSITION_DELETE_PERMISSION,
  DAILY_IMPORT_POSITION_EDIT_PERMISSION,
  DAILY_IMPORT_POSITION_VIEW_PERMISSION,
  DAILY_LINE_PLAN_VIEW_PERMISSION,
  DAILY_LINE_TARGET_CREATE_PERMISSION,
  DAILY_LINE_TARGET_DELETE_PERMISSION,
  DAILY_LINE_TARGET_EDIT_PERMISSION,
  DAILY_LINE_TARGET_VIEW_PERMISSION,
  DAILY_SEWING_PERFORMANCE_VIEW_PERMISSION,
  DELETE_MRR_REPORT_VIEW_PERMISSION,
  DIGITALIZATION_ABSENTEEISM_VIEW_PERMISSION,
  DIGITALIZATION_DEFECT_VIEW_PERMISSION,
  DIGITALIZATION_MENU_VIEW_PERMISSION,
  DIGITALIZATION_SETTINGS_VIEW_PERMISSION,
  DRIVER_VEHICLE_INFO_CREATE_PERMISSION,
  DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
  DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION,
  DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
  DRIVER_VEHICLE_INFO_VIEW_PERMISSION,
  EMBROIDERY_PRINTING_SMOCKING_PINTUCK_VIEW_PERMISSION,
  ERP_USAGE_MODULE_VIEW_PERMISSION,
  EXPORT_DETAILS_CREATE_PERMISSION,
  EXPORT_DETAILS_DELETE_PERMISSION,
  EXPORT_DETAILS_EDIT_PERMISSION,
  EXPORT_DETAILS_VIEW_PERMISSION,
  FABRIC_ACCESSORIES_AUTO_RECONCILIATION_VIEW_PERMISSION,
  FABRIC_REPORT_DELETE_PERMISSION,
  FABRIC_REPORT_GENERATE_PERMISSION,
  FABRIC_REPORT_VIEW_PERMISSION,
  FINISH_GARMENT_CREATE_PERMISSION,
  FINISH_GARMENT_DELETE_PERMISSION,
  FINISH_GARMENT_EDIT_PERMISSION,
  FINISH_GARMENT_VIEW_PERMISSION,
  FOLDING_CREATE_PERMISSION,
  FOLDING_DELETE_PERMISSION,
  FOLDING_EDIT_PERMISSION,
  FOLDING_VIEW_PERMISSION,
  GARMENTS_PARTS_CREATE_PERMISSION,
  GARMENTS_PARTS_DELETE_PERMISSION,
  GARMENTS_PARTS_EDIT_PERMISSION,
  GARMENTS_PARTS_VIEW_PERMISSION,
  GARMENTS_TRACKING_VIEW_PERMISSION,
  GARMENT_CATEGORY_CREATE_PERMISSION,
  GARMENT_CATEGORY_DELETE_PERMISSION,
  GARMENT_CATEGORY_EDIT_PERMISSION,
  GARMENT_CATEGORY_VIEW_PERMISSION,
  GARMENT_TYPE_CREATE_PERMISSION,
  GARMENT_TYPE_DELETE_PERMISSION,
  GARMENT_TYPE_EDIT_PERMISSION,
  GARMENT_TYPE_VIEW_PERMISSION,
  GENERATE_MRR_REPORT_VIEW_PERMISSION,
  GOODS_DELIVERY_CREATE_PERMISSION,
  GOODS_DELIVERY_DELETE_PERMISSION,
  GOODS_DELIVERY_EDIT_PERMISSION,
  GOODS_DELIVERY_VIEW_PERMISSION,
  HOURLY_PRODUCTION_VIEW_PERMISSION,
  HR_OT_EXPENSE_DATA_IMPORT_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_DELETE_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION,
  IMPORT_ACCEPTANCE_DETAIL_VIEW_PERMISSION,
  INQUIRY_REASONS_CREATE_PERMISSION,
  INQUIRY_REASONS_DELETE_PERMISSION,
  INQUIRY_REASONS_EDIT_PERMISSION,
  INQUIRY_REASONS_VIEW_PERMISSION,
  INQUIRY_REMARKS_CREATE_PERMISSION,
  INQUIRY_REMARKS_DELETE_PERMISSION,
  INQUIRY_REMARKS_EDIT_PERMISSION,
  INQUIRY_REMARKS_VIEW_PERMISSION,
  INVENTORY_CREATE_PERMISSION,
  INVENTORY_DELETE_PERMISSION,
  INVENTORY_DETAIL_REPORT_DELETE_PERMISSION,
  INVENTORY_DETAIL_REPORT_GENERATE_PERMISSION,
  INVENTORY_DETAIL_REPORT_VIEW_PERMISSION,
  INVENTORY_EDIT_PERMISSION,
  INVENTORY_MRR_REPORT_VIEW_PERMISSION,
  INVENTORY_VIEW_PERMISSION,
  IPR_REPORT_VIEW_PERMISSION,
  ITEM_CATEGORY_CREATE_PERMISSION,
  ITEM_CATEGORY_DELETE_PERMISSION,
  ITEM_CATEGORY_EDIT_PERMISSION,
  ITEM_CATEGORY_VIEW_PERMISSION,
  ITEM_CREATE_PERMISSION,
  ITEM_DELETE_PERMISSION,
  ITEM_EDIT_PERMISSION,
  ITEM_OCR_UPLOAD_PERMISSION,
  ITEM_VIEW_PERMISSION,
  LEARNING_CURVE_CREATE_PERMISSION,
  LEARNING_CURVE_DELETE_PERMISSION,
  LEARNING_CURVE_EDIT_PERMISSION,
  LEARNING_CURVE_VIEW_PERMISSION,
  LINE_WISE_SEWING_REPORT_VIEW_PERMISSION,
  LOCAL_PURCHASE_RECONCILIATION_CREATE_PERMISSION,
  LOCAL_PURCHASE_RECONCILIATION_DELETE_PERMISSION,
  LOCAL_PURCHASE_RECONCILIATION_EDIT_PERMISSION,
  LOCAL_PURCHASE_RECONCILIATION_VIEW_PERMISSION,
  NOTIFICATION_SETTING_CREATE_PERMISSION,
  NOTIFICATION_SETTING_DELETE_PERMISSION,
  NOTIFICATION_SETTING_EDIT_PERMISSION,
  NOTIFICATION_SETTING_VIEW_PERMISSION,
  NOTIFICATION_SLUG_CREATE_PERMISSION,
  NOTIFICATION_SLUG_DELETE_PERMISSION,
  NOTIFICATION_SLUG_EDIT_PERMISSION,
  NOTIFICATION_SLUG_VIEW_PERMISSION,
  NOTING_ASSESSMENT_CREATE_PERMISSION,
  NOTING_ASSESSMENT_DELETE_PERMISSION,
  NOTING_ASSESSMENT_EDIT_PERMISSION,
  NOTING_ASSESSMENT_VIEW_PERMISSION,
  NO_PERMISSION_REQUIRED,
  OB_CREATE_PERMISSION,
  OB_DELETE_PERMISSION,
  OB_EDIT_PERMISSION,
  OB_MACHINE_CREATE_PERMISSION,
  OB_MACHINE_DELETE_PERMISSION,
  OB_MACHINE_EDIT_PERMISSION,
  OB_MACHINE_VIEW_PERMISSION,
  OB_SAM_DATA_BANK_CREATE_PERMISSION,
  OB_SAM_DATA_BANK_DELETE_PERMISSION,
  OB_SAM_DATA_BANK_EDIT_PERMISSION,
  OB_SAM_DATA_BANK_VIEW_PERMISSION,
  OB_SECTION_CREATE_PERMISSION,
  OB_SECTION_DELETE_PERMISSION,
  OB_SECTION_EDIT_PERMISSION,
  OB_SECTION_VIEW_PERMISSION,
  OB_TEMPLATE_CREATE_PERMISSION,
  OB_TEMPLATE_DELETE_PERMISSION,
  OB_TEMPLATE_EDIT_PERMISSION,
  OB_TEMPLATE_VIEW_PERMISSION,
  OB_VIEW_PERMISSION,
  ORGANIZATION_CREATE_PERMISSION,
  ORGANIZATION_DELETE_PERMISSION,
  ORGANIZATION_EDIT_PERMISSION,
  ORGANIZATION_VIEW_PERMISSION,
  OSR_REPORT_GENERATE_PERMISSION,
  OT_SIGNAL_SECTION_VIEW_PERMISSION,
  OT_SIGNAL_VIEW_PERMISSION,
  PACKING_CREATE_PERMISSION,
  PACKING_DELETE_PERMISSION,
  PACKING_EDIT_PERMISSION,
  PACKING_LIST_REPORT_VIEW_PERMISSION,
  PACKING_VIEW_PERMISSION,
  PERMISSION_CREATE_PERMISSION,
  PERMISSION_DELETE_PERMISSION,
  PERMISSION_EDIT_PERMISSION,
  PERMISSION_VIEW_PERMISSION,
  PI_CREATE_PERMISSION,
  PI_DELETE_PERMISSION,
  PI_EDIT_PERMISSION,
  PI_VIEW_PERMISSION,
  PLAN_CREATE_PERMISSION,
  PLAN_DASHBOARD_VIEW_PERMISSION,
  PNL_REPORT_VIEW_PERMISSION,
  POST_COSTING_REPORT_GENERATE_PERMISSION,
  PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION,
  PP_EXCEPTION_CREATE_PERMISSION,
  PP_EXCEPTION_DELETE_PERMISSION,
  PP_EXCEPTION_EDIT_PERMISSION,
  PP_EXCEPTION_VIEW_PERMISSION,
  PP_HOLIDAY_CREATE_PERMISSION,
  PP_HOLIDAY_DELETE_PERMISSION,
  PP_HOLIDAY_EDIT_PERMISSION,
  PP_HOLIDAY_VIEW_PERMISSION,
  PP_PLANNING_REQUEST_CREATE_PERMISSION,
  PP_PLANNING_REQUEST_DELETE_PERMISSION,
  PP_PLANNING_REQUEST_EDIT_PERMISSION,
  PP_PLANNING_REQUEST_VIEW_PERMISSION,
  PP_WEEKEND_CREATE_PERMISSION,
  PP_WEEKEND_DELETE_PERMISSION,
  PP_WEEKEND_EDIT_PERMISSION,
  PP_WEEKEND_VIEW_PERMISSION,
  PRODUCT_DETAIL_REPORT_DELETE_PERMISSION,
  PRODUCT_DETAIL_REPORT_GENERATE_PERMISSION,
  PRODUCT_TRACK_REPORT_DELETE_PERMISSION,
  PRODUCT_TRACK_REPORT_GENERATE_PERMISSION,
  PRODUCT_TRACK_REPORT_VIEW_PERMISSION,
  PURCHASE_ORDER_CREATE_PERMISSION,
  PURCHASE_ORDER_DELETE_PERMISSION,
  PURCHASE_ORDER_EDIT_PERMISSION,
  PURCHASE_ORDER_VIEW_PERMISSION,
  RACK_CREATE_PERMISSION,
  RACK_DELETE_PERMISSION,
  RACK_EDIT_PERMISSION,
  RACK_VIEW_PERMISSION,
  ROLE_CREATE_PERMISSION,
  ROLE_DELETE_PERMISSION,
  ROLE_EDIT_PERMISSION,
  ROLE_VIEW_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_DELETE_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION,
  SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION,
  SALES_CONTRACT_CREATE_PERMISSION,
  SALES_CONTRACT_DELETE_PERMISSION,
  SALES_CONTRACT_EDIT_PERMISSION,
  SALES_CONTRACT_VIEW_PERMISSION,
  SEWING_CREATE_PERMISSION,
  SEWING_DELETE_PERMISSION,
  SEWING_EDIT_PERMISSION,
  SEWING_FOLDING_REPORT_GENERATE_PERMISSION,
  SEWING_LINE_CREATE_PERMISSION,
  SEWING_LINE_DELETE_PERMISSION,
  SEWING_LINE_EDIT_PERMISSION,
  SEWING_LINE_VIEW_PERMISSION,
  SEWING_VIEW_PERMISSION,
  SHIPMENT_HANDOVER_CREATE_PERMISSION,
  SHIPMENT_HANDOVER_DELETE_PERMISSION,
  SHIPMENT_HANDOVER_EDIT_PERMISSION,
  SHIPMENT_HANDOVER_VIEW_PERMISSION,
  SHIPPING_CREATE_PERMISSION,
  SHIPPING_DELETE_PERMISSION,
  SHIPPING_EDIT_PERMISSION,
  SHIPPING_VIEW_PERMISSION,
  SIZE_CREATE_PERMISSION,
  SIZE_DELETE_PERMISSION,
  SIZE_EDIT_PERMISSION,
  SIZE_VIEW_PERMISSION,
  STYLE_CREATE_PERMISSION,
  STYLE_DELETE_PERMISSION,
  STYLE_EDIT_PERMISSION,
  STYLE_VIEW_PERMISSION,
  SUBCONTRACT_FACTORY_VIEW_PERMISSION,
  SUBMISSION_REALIZATION_CREATE_PERMISSION,
  SUBMISSION_REALIZATION_DELETE_PERMISSION,
  SUBMISSION_REALIZATION_EDIT_PERMISSION,
  SUBMISSION_REALIZATION_VIEW_PERMISSION,
  SUPERVISOR_CHECKLIST_CREATE_PERMISSION,
  SUPERVISOR_CHECKLIST_DELETE_PERMISSION,
  SUPERVISOR_CHECKLIST_EDIT_PERMISSION,
  SUPERVISOR_CHECKLIST_VIEW_PERMISSION,
  SUPPLIER_CREATE_PERMISSION,
  SUPPLIER_DELETE_PERMISSION,
  SUPPLIER_EDIT_PERMISSION,
  SUPPLIER_VIEW_PERMISSION,
  TNA_DEPARTMENT_CREATE_PERMISSION,
  TNA_DEPARTMENT_DELETE_PERMISSION,
  TNA_DEPARTMENT_EDIT_PERMISSION,
  TNA_DEPARTMENT_VIEW_PERMISSION,
  TNA_PLAN_CREATE_PERMISSION,
  TNA_PLAN_DELETE_PERMISSION,
  TNA_PLAN_EDIT_PERMISSION,
  TNA_PLAN_VIEW_PERMISSION,
  TNA_PROCESS_CREATE_PERMISSION,
  TNA_PROCESS_DELETE_PERMISSION,
  TNA_PROCESS_EDIT_PERMISSION,
  TNA_PROCESS_VIEW_PERMISSION,
  TNA_SUMMARY_VIEW_PERMISSION,
  TNA_TASK_CREATE_PERMISSION,
  TNA_TASK_DELETE_PERMISSION,
  TNA_TASK_EDIT_PERMISSION,
  TNA_TASK_VIEW_PERMISSION,
  TNA_TEMPLATE_CREATE_PERMISSION,
  TNA_TEMPLATE_DELETE_PERMISSION,
  TNA_TEMPLATE_EDIT_PERMISSION,
  TNA_TEMPLATE_VIEW_PERMISSION,
  TNA_TO_DO_LIST_VIEW_PERMISSION,
  UNIT_CREATE_PERMISSION,
  UNIT_DELETE_PERMISSION,
  UNIT_EDIT_PERMISSION,
  UNIT_VIEW_PERMISSION,
  UPLOAD_BANK_PERMISSION,
  UPLOAD_BOM_PERMISSION,
  UPLOAD_BRANCH_PERMISSION,
  UPLOAD_BRAND_PERMISSION,
  UPLOAD_BUYER_PERMISSION,
  UPLOAD_BUYING_OFFICE_PERMISSION,
  UPLOAD_COLOR_PERMISSION,
  UPLOAD_MERCHANDISER_PERMISSION,
  UPLOAD_PO_PERMISSION,
  UPLOAD_SALES_CONTRACT_PERMISSION,
  UPLOAD_SIZE_PERMISSION,
  UPLOAD_STYLE_PERMISSION,
  UPLOAD_SUBSTYLE_PERMISSION,
  UPLOAD_SUPPLIER_PERMISSION,
  USER_CREATE_PERMISSION,
  USER_DELETE_PERMISSION,
  USER_EDIT_PERMISSION,
  USER_VIEW_PERMISSION,
  VIEW_ALL_ACTIVE_POS_PERMISSION,
  WAREHOUSE_CREATE_PERMISSION,
  WAREHOUSE_DELETE_PERMISSION,
  WAREHOUSE_EDIT_PERMISSION,
  WAREHOUSE_VIEW_PERMISSION,
  WASHING_SNAP_BUTTON_VIEW_PERMISSION,
} from "./permissions";

const defaultMenuItems = [
  {
    icon: <HomeOutlined />,
    label: (
      <Link to={"/"} replace={true}>
        Dashboard{" "}
      </Link>
    ),
    to: "/dashboard",
    key: "/dashboard",
    breadcrumbname: "Dashboard",
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    icon: <RadiusSettingOutlined />,
    label: "Configuration",
    to: "#",
    key: "/configuration",
    children: [
      {
        label: <Link to="/configuration/organization"> Organizations </Link>,
        key: "/configuration/organization",
        to: "/configuration/organization",
        breadcrumbname: "Organizations",
        allowedpermissions: [
          ORGANIZATION_VIEW_PERMISSION,
          ORGANIZATION_CREATE_PERMISSION,
          ORGANIZATION_EDIT_PERMISSION,
          ORGANIZATION_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/configuration/buyer" replace={true}>
            {" "}
            Buyer{" "}
          </Link>
        ),
        key: "/configuration/buyer",
        to: "/configuration/buyer",
        breadcrumbname: "Buyer",
        allowedpermissions: [
          BUYER_VIEW_PERMISSION,
          BUYER_CREATE_PERMISSION,
          BUYER_EDIT_PERMISSION,
          BUYER_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/configuration/buying-office"} replace={true}>
            {" "}
            Buying Office{" "}
          </Link>
        ),
        key: "/configuration/buying-office",
        to: "/configuration/buying-office",
        breadcrumbname: "Buying Office",
        allowedpermissions: [
          BUYING_OFFICE_VIEW_PERMISSION,
          BUYING_OFFICE_CREATE_PERMISSION,
          BUYING_OFFICE_EDIT_PERMISSION,
          BUYING_OFFICE_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/item-category"}>Item Category</Link>,
        key: "/configuration/item-category",
        to: "/configuration/item-category",
        breadcrumbname: "Item Category",
        allowedpermissions: [
          ITEM_CATEGORY_VIEW_PERMISSION,
          ITEM_CATEGORY_CREATE_PERMISSION,
          ITEM_CATEGORY_EDIT_PERMISSION,
          ITEM_CATEGORY_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/items"}>Item</Link>,
        key: "/configuration/items",
        to: "/configuration/items",
        breadcrumbname: "Item",
        allowedpermissions: [
          ITEM_VIEW_PERMISSION,
          ITEM_CREATE_PERMISSION,
          ITEM_EDIT_PERMISSION,
          ITEM_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/configuration/upload-item-ocr"}>Upload Item OCR</Link>
        ),
        key: "/configuration/upload-item-ocr",
        to: "/configuration/upload-item-ocr",
        breadcrumbname: "Upload Item OCR",
        allowedpermissions: [ITEM_OCR_UPLOAD_PERMISSION],
      },
      {
        label: <Link to={"/configuration/sewing-line"}>Sewing Line</Link>,
        key: "/configuration/sewing-line",
        to: "/configuration/sewing-line",
        breadcrumbname: "Sewing Line",
        allowedpermissions: [
          SEWING_LINE_VIEW_PERMISSION,
          SEWING_LINE_CREATE_PERMISSION,
          SEWING_LINE_EDIT_PERMISSION,
          SEWING_LINE_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/warehouse"}>Warehouse</Link>,
        key: "/configuration/warehouse",
        to: "/configuration/warehouse",
        breadcrumbname: "Warehouse",
        allowedpermissions: [
          WAREHOUSE_VIEW_PERMISSION,
          WAREHOUSE_CREATE_PERMISSION,
          WAREHOUSE_EDIT_PERMISSION,
          WAREHOUSE_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/rack"}>Rack</Link>,
        key: "/configuration/rack",
        to: "/configuration/rack",
        breadcrumbname: "Rack",
        allowedpermissions: [
          RACK_VIEW_PERMISSION,
          RACK_CREATE_PERMISSION,
          RACK_EDIT_PERMISSION,
          RACK_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/bin"}>Bin</Link>,
        key: "/configuration/bin",
        to: "/configuration/bin",
        breadcrumbname: "Bin",
        allowedpermissions: [
          BIN_VIEW_PERMISSION,
          BIN_CREATE_PERMISSION,
          BIN_EDIT_PERMISSION,
          BIN_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/unit"}>Unit</Link>,
        key: "/configuration/unit",
        to: "/configuration/unit",
        breadcrumbname: "Unit",
        allowedpermissions: [
          UNIT_VIEW_PERMISSION,
          UNIT_CREATE_PERMISSION,
          UNIT_EDIT_PERMISSION,
          UNIT_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/supplier"}>Supplier</Link>,
        key: "/configuration/supplier",
        to: "/configuration/supplier",
        breadcrumbname: "Supplier",
        allowedpermissions: [
          SUPPLIER_VIEW_PERMISSION,
          SUPPLIER_CREATE_PERMISSION,
          SUPPLIER_EDIT_PERMISSION,
          SUPPLIER_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/color"}>Color</Link>,
        key: "/configuration/color",
        to: "/configuration/color",
        breadcrumbname: "color",
        allowedpermissions: [
          COLOR_VIEW_PERMISSION,
          COLOR_CREATE_PERMISSION,
          COLOR_EDIT_PERMISSION,
          COLOR_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/size"}>Size</Link>,
        key: "/configuration/size",
        to: "/configuration/size",
        breadcrumbname: "size",
        allowedpermissions: [
          SIZE_VIEW_PERMISSION,
          SIZE_CREATE_PERMISSION,
          SIZE_EDIT_PERMISSION,
          SIZE_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/configuration/unit-convertion"}>Unit Convertion</Link>
        ),
        key: "/configuration/unit-convertion",
        to: "/configuration/unit-convertion",
        breadcrumbname: "unit-convertion",
        allowedpermissions: [
          UNIT_VIEW_PERMISSION,
          UNIT_CREATE_PERMISSION,
          UNIT_EDIT_PERMISSION,
          UNIT_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/challan"}>Challan</Link>,
        key: "/configuration/challan",
        to: "/configuration/challan",
        breadcrumbname: "Challan",
        allowedpermissions: [
          CHALLAN_CREATE_PERMISSION,
          CHALLAN_EDIT_PERMISSION,
          CHALLAN_VIEW_PERMISSION,
          CHALLAN_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/configuration/garment-category"}>Garment Category</Link>
        ),
        key: "/configuration/garment-category",
        to: "/configuration/garment-category",
        breadcrumbname: "Garment Category",
        allowedpermissions: [
          GARMENT_CATEGORY_VIEW_PERMISSION,
          GARMENT_CATEGORY_CREATE_PERMISSION,
          GARMENT_CATEGORY_EDIT_PERMISSION,
          GARMENT_CATEGORY_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/garment-type"}>Garment Type</Link>,
        key: "/configuration/garment-type",
        to: "/configuration/garment-type",
        breadcrumbname: "Garment Type",
        allowedpermissions: [
          GARMENT_TYPE_VIEW_PERMISSION,
          GARMENT_TYPE_CREATE_PERMISSION,
          GARMENT_TYPE_EDIT_PERMISSION,
          GARMENT_TYPE_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/merchandising/sub-contract-factory">
            Sub-Contract Factory
          </Link>
        ),
        key: "/merchandising/sub-contract-factory",
        to: "/merchandising/sub-contract-factory",
        breadcrumbname: "User Line Assign",
        allowedpermissions: [SUBCONTRACT_FACTORY_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to={"/configuration/inquiry-reasons"}>Inquiry Reasons</Link>
        ),
        key: "/configuration/inquiry-reasons",
        to: "/configuration/inquiry-reasons",
        breadcrumbname: "Inquiry Reasons",
        allowedpermissions: [
          INQUIRY_REASONS_VIEW_PERMISSION,
          INQUIRY_REASONS_CREATE_PERMISSION,
          INQUIRY_REASONS_EDIT_PERMISSION,
          INQUIRY_REASONS_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/configuration/inquiry-remarks"}>Inquiry Remarks</Link>
        ),
        key: "/configuration/inquiry-remarks",
        to: "/configuration/inquiry-remarks",
        breadcrumbname: "Inquiry Remarks",
        allowedpermissions: [
          INQUIRY_REMARKS_VIEW_PERMISSION,
          INQUIRY_REMARKS_CREATE_PERMISSION,
          INQUIRY_REMARKS_EDIT_PERMISSION,
          INQUIRY_REMARKS_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to="/ot-signal-section">OT Signal Section</Link>,
        key: "/ot-signal-section",
        to: "/ot-signal-section",
        breadcrumbname: "OT Signal Section",
        allowedpermissions: [OT_SIGNAL_SECTION_VIEW_PERMISSION],
      },
      {
        label: <Link to="/ot-signal">OT Signal</Link>,
        key: "/ot-signal",
        to: "/ot-signal",
        breadcrumbname: "OT Signal",
        allowedpermissions: [OT_SIGNAL_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to="/configuration/banks" replace={true}>
            {" "}
            Banks{" "}
          </Link>
        ),
        key: "/configuration/banks",
        to: "/configuration/banks",
        breadcrumbname: "Banks",
        allowedpermissions: [
          BANK_VIEW_PERMISSION,
          BANK_CREATE_PERMISSION,
          BANK_EDIT_PERMISSION,
          BANK_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/configuration/branches" replace={true}>
            {" "}
            Branches{" "}
          </Link>
        ),
        key: "/configuration/branches",
        to: "/configuration/branches",
        breadcrumbname: "Branches",
        allowedpermissions: [
          BRANCH_VIEW_PERMISSION,
          BRANCH_CREATE_PERMISSION,
          BRANCH_EDIT_PERMISSION,
          BRANCH_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/configuration/accounts" replace={true}>
            {" "}
            Accounts{" "}
          </Link>
        ),
        key: "/configuration/accounts",
        to: "/configuration/accounts",
        breadcrumbname: "Accounts",
        allowedpermissions: [
          ACCOUNT_VIEW_PERMISSION,
          ACCOUNT_CREATE_PERMISSION,
          ACCOUNT_EDIT_PERMISSION,
          ACCOUNT_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to="/configuration/garments-parts">Garments Parts</Link>,
        key: "/configuration/garments-parts",
        to: "/configuration/garments-parts",
        breadcrumbname: "Garments Parts",
        allowedpermissions: [
          GARMENTS_PARTS_CREATE_PERMISSION,
          GARMENTS_PARTS_EDIT_PERMISSION,
          GARMENTS_PARTS_VIEW_PERMISSION,
          GARMENTS_PARTS_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <SettingOutlined />,
    label: "Settings",
    key: "/settings",
    children: [
      {
        label: <Link to={"/settings/users"}>Users</Link>,
        key: "/settings/users",
        to: "/settings/users",
        allowedpermissions: [
          USER_VIEW_PERMISSION,
          USER_CREATE_PERMISSION,
          USER_EDIT_PERMISSION,
          USER_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/settings/role"} replace={true}>
            User Role
          </Link>
        ),
        key: "/settings/role",
        to: "/settings/role",
        breadcrumbname: "UserRole",
        allowedpermissions: [
          ROLE_VIEW_PERMISSION,
          ROLE_CREATE_PERMISSION,
          ROLE_EDIT_PERMISSION,
          ROLE_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/settings/permissions"} replace={true}>
            Permission
          </Link>
        ),
        key: "/settings/permissions",
        to: "/settings/permissions",
        breadcrumbname: "Permissions",
        allowedpermissions: [NO_PERMISSION_REQUIRED],
      },
      {
        label: <Link to={"/tanda/department"}>Department</Link>,
        key: "/tanda/department",
        to: "/department",
        allowedpermissions: [
          TNA_DEPARTMENT_CREATE_PERMISSION,
          TNA_DEPARTMENT_VIEW_PERMISSION,
          TNA_DEPARTMENT_EDIT_PERMISSION,
          TNA_DEPARTMENT_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/settings/notification-slugs"} replace={true}>
            Notification Slugs
          </Link>
        ),
        key: "/settings/notification-slugs",
        to: "/settings/notification-slugs",
        allowedpermissions: [
          NOTIFICATION_SLUG_VIEW_PERMISSION,
          NOTIFICATION_SLUG_CREATE_PERMISSION,
          NOTIFICATION_SLUG_EDIT_PERMISSION,
          NOTIFICATION_SLUG_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/settings/notification-settings"} replace={true}>
            Notification Settings
          </Link>
        ),
        key: "/settings/notification-settings",
        to: "/settings/notification-settings",
        breadcrumbname: "NotificationSettings",
        allowedpermissions: [
          NOTIFICATION_SETTING_VIEW_PERMISSION,
          NOTIFICATION_SETTING_CREATE_PERMISSION,
          NOTIFICATION_SETTING_EDIT_PERMISSION,
          NOTIFICATION_SETTING_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/settings/permission"} replace={true}>
            Permission Settings
          </Link>
        ),
        key: "/settings/permission",
        to: "/settings/permission",
        breadcrumbname: "PermissionSettings",
        allowedpermissions: [
          PERMISSION_VIEW_PERMISSION,
          PERMISSION_CREATE_PERMISSION,
          PERMISSION_EDIT_PERMISSION,
          PERMISSION_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <ThunderboltOutlined />,
    label: "Production Planning",
    key: "/production-planning/dashboard",
    children: [
      {
        label: (
          <Link to={"/production-planning/dashboard"} replace={true}>
            Dashboard
          </Link>
        ),
        key: "/production-planning/dashboard",
        to: "/production-planning/dashboard",
        allowedpermissions: [PLAN_DASHBOARD_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to={"/production-planning/weekends"} replace={true}>
            Weekends
          </Link>
        ),
        key: "/production-planning/weekends",
        to: "/production-planning/weekends",
        allowedpermissions: [
          PP_WEEKEND_VIEW_PERMISSION,
          PP_WEEKEND_CREATE_PERMISSION,
          PP_WEEKEND_EDIT_PERMISSION,
          PP_WEEKEND_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/production-planning/holidays"} replace={true}>
            Holiday
          </Link>
        ),
        key: "/production-planning/holidays",
        to: "/production-planning/holidays",
        allowedpermissions: [
          PP_HOLIDAY_VIEW_PERMISSION,
          PP_HOLIDAY_CREATE_PERMISSION,
          PP_HOLIDAY_EDIT_PERMISSION,
          PP_HOLIDAY_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/production-planning/exceptions"} replace={true}>
            Exceptions
          </Link>
        ),
        key: "/production-planning/exceptions",
        to: "/production-planning/exceptions",
        allowedpermissions: [
          PP_EXCEPTION_VIEW_PERMISSION,
          PP_EXCEPTION_CREATE_PERMISSION,
          PP_EXCEPTION_EDIT_PERMISSION,
          PP_EXCEPTION_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/production-planning/plan-entry"} replace={true}>
            Plan Entry
          </Link>
        ),
        key: "/production-planning/plan-entry",
        to: "/production-planning/plan-entry",
        allowedpermissions: [PLAN_CREATE_PERMISSION],
      },
    ],
  },
  {
    icon: <SkinOutlined />,
    label: "Merchandising",
    key: "/merchandising",
    children: [
      {
        label: <Link to={"/merchandising/buyer-inquiry"}>Buyer Inquiry</Link>,
        key: "/merchandising/buyer-inquiry",
        to: "/buyer-inquiry",
        allowedpermissions: [
          BUYER_INQUIRY_VIEW_PERMISSION,
          BUYER_INQUIRY_CREATE_PERMISSION,
          BUYER_INQUIRY_EDIT_PERMISSION,
          BUYER_INQUIRY_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/merchandising/style"}>Style </Link>,
        key: "/merchandising/style",
        to: "/style",
        allowedpermissions: [
          STYLE_VIEW_PERMISSION,
          STYLE_CREATE_PERMISSION,
          STYLE_EDIT_PERMISSION,
          STYLE_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/merchandising/sales-contact"}>Sales Contract</Link>,
        key: "/merchandising/sales-contact",
        to: "/sales-contact",
        allowedpermissions: [
          SALES_CONTRACT_VIEW_PERMISSION,
          SALES_CONTRACT_CREATE_PERMISSION,
          SALES_CONTRACT_EDIT_PERMISSION,
          SALES_CONTRACT_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/merchandising/sub-sales-contact"}>Sub Contract</Link>
        ),
        key: "/merchandising/sub-sales-contact",
        to: "/merchandising/sub-sales-contact",
        allowedpermissions: [
          SALES_CONTRACT_VIEW_PERMISSION,
          SALES_CONTRACT_CREATE_PERMISSION,
          SALES_CONTRACT_EDIT_PERMISSION,
          SALES_CONTRACT_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/production-planning/planningRequests"} replace={true}>
            Planning Request
          </Link>
        ),
        key: "/production-planning/planningRequests",
        to: "/production-planning/planningRequests",
        allowedpermissions: [
          PP_PLANNING_REQUEST_CREATE_PERMISSION,
          PP_PLANNING_REQUEST_EDIT_PERMISSION,
          PP_PLANNING_REQUEST_VIEW_PERMISSION,
          PP_PLANNING_REQUEST_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/merchandising/purchase-order-breakdown"}>
            Purchase Order Breakdown
          </Link>
        ),
        key: "/merchandising/purchase-order-breakdown",
        to: "/purchase-order-breakdown",
        allowedpermissions: [
          PURCHASE_ORDER_VIEW_PERMISSION,
          PURCHASE_ORDER_CREATE_PERMISSION,
          PURCHASE_ORDER_EDIT_PERMISSION,
          PURCHASE_ORDER_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/merchandising/style-wise-quantity-breakdown"}>
            Style Wise Qty Breakdown
          </Link>
        ),
        key: "/merchandising/style-wise-quantity-breakdown",
        to: "/order-details",
        allowedpermissions: [
          PURCHASE_ORDER_VIEW_PERMISSION,
          PURCHASE_ORDER_CREATE_PERMISSION,
          PURCHASE_ORDER_EDIT_PERMISSION,
          PURCHASE_ORDER_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/merchandising/cost-estimate"}>Cost Estimate</Link>,
        key: "/merchandising/cost-estimate",
        to: "/merchandising/cost-estimate",
        allowedpermissions: [
          COST_ESTIMATE_CREATE_PERMISSION,
          COST_ESTIMATE_EDIT_PERMISSION,
          COST_ESTIMATE_VIEW_PERMISSION,
          COST_ESTIMATE_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/merchandising/BOM"}>Bill Of Materials</Link>,
        key: "/merchandising/BOM",
        to: "/BOM",
        allowedpermissions: [
          BOM_VIEW_PERMISSION,
          BOM_CREATE_PERMISSION,
          BOM_EDIT_PERMISSION,
          BOM_DELETE_PERMISSION,
          BOM_EXPORT_PERMISSION,
        ],
      },
      {
        label: <Link to="/import/pi">Proforma Invoice</Link>,
        key: "/import/pi",
        to: "#",
        breadcrumbname: "Sales Contract",
        allowedpermissions: [
          PI_VIEW_PERMISSION,
          PI_CREATE_PERMISSION,
          PI_EDIT_PERMISSION,
          PI_DELETE_PERMISSION,
        ],
      },
      // {
      //   label: <Link to="/import/budget-pi">Budget VS PI</Link>,
      //   key: "/import/budget-pi",
      //   to: "#",
      //   breadcrumbname: "Sales Contract",
      //   allowedpermissions: [PI_BUDGET_VIEW_PERMISSION],
      // },
      // {
      //   label: (
      //     <Link to={"/merchandising/purchase-order-new"}>Buyer PO (New)</Link>
      //   ),
      //   key: "/merchandising/purchase-order-new",
      //   to: "/order-details",
      //   allowedpermissions: [
      //     "purchase-order-view-permission",
      //     "purchase-order-create-permission",
      //     "purchase-order-edit-permission",
      //     "purchase-order-delete-permission",
      //   ],
      // },

      {
        label: (
          <Link to={"/merchandising/sales-contract-360"}>
            Sales Contract 360
          </Link>
        ),
        key: "/merchandising/sales-contract-360",
        to: "/sales-contract-360",
        // TODO Add Permissions SALES_CONTRACT_360_VIEW_PERMISSION
        allowedpermissions: [
          SALES_CONTRACT_VIEW_PERMISSION,
          SALES_CONTRACT_CREATE_PERMISSION,
          SALES_CONTRACT_EDIT_PERMISSION,
          SALES_CONTRACT_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <ClusterOutlined />,
    label: "T & A",
    key: "/tanda",
    children: [
      {
        label: <Link to={"/tanda/process"}>Process</Link>,
        key: "/tanda/process",
        to: "/process",
        allowedpermissions: [
          TNA_PROCESS_CREATE_PERMISSION,
          TNA_PROCESS_VIEW_PERMISSION,
          TNA_PROCESS_EDIT_PERMISSION,
          TNA_PROCESS_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/tanda/task"}>Task</Link>,
        key: "/tanda/task",
        to: "/task",
        allowedpermissions: [
          TNA_TASK_CREATE_PERMISSION,
          TNA_TASK_VIEW_PERMISSION,
          TNA_TASK_EDIT_PERMISSION,
          TNA_TASK_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/tanda/template"}>Template</Link>,
        key: "/tanda/template",
        to: "/template",
        allowedpermissions: [
          TNA_TEMPLATE_CREATE_PERMISSION,
          TNA_TEMPLATE_VIEW_PERMISSION,
          TNA_TEMPLATE_EDIT_PERMISSION,
          TNA_TEMPLATE_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/tanda/plan-list"}>TNA</Link>,
        key: "/tanda/plan-list",
        to: "/plan",
        allowedpermissions: [
          TNA_PLAN_CREATE_PERMISSION,
          TNA_PLAN_VIEW_PERMISSION,
          TNA_PLAN_EDIT_PERMISSION,
          TNA_PLAN_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/tanda/todo-list"}>Todo List</Link>,
        key: "/tanda/todo-lis",
        to: "/tanda/todo-lis",
        allowedpermissions: [TNA_TO_DO_LIST_VIEW_PERMISSION],
      },
      {
        label: <Link to={"/tanda/summary"}>Summary</Link>,
        key: "/tanda/summary",
        to: "/summary",
        allowedpermissions: [TNA_SUMMARY_VIEW_PERMISSION],
      },
    ],
  },
  {
    icon: <RobotOutlined />,
    label: "Industrial Engineering (IE)",
    key: "/industrial-engineering",
    children: [
      {
        label: (
          <Link to={"/merchandising/learning-curves"}>Learning Curve </Link>
        ),
        key: "/merchandising/learning-curves",
        to: "/merchandising/learning-curves",
        allowedpermissions: [
          LEARNING_CURVE_VIEW_PERMISSION,
          LEARNING_CURVE_CREATE_PERMISSION,
          LEARNING_CURVE_EDIT_PERMISSION,
          LEARNING_CURVE_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/ob-machine"}>OB Machine</Link>,
        key: "/configuration/ob-machine",
        to: "/configuration/ob-machine",
        breadcrumbname: "ob-machine",
        allowedpermissions: [
          OB_MACHINE_VIEW_PERMISSION,
          OB_MACHINE_CREATE_PERMISSION,
          OB_MACHINE_EDIT_PERMISSION,
          OB_MACHINE_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/ob-section"}>OB Section</Link>,
        key: "/configuration/ob-section",
        to: "/configuration/ob-section",
        breadcrumbname: "ob-section",
        allowedpermissions: [
          OB_SECTION_VIEW_PERMISSION,
          OB_SECTION_CREATE_PERMISSION,
          OB_SECTION_EDIT_PERMISSION,
          OB_SECTION_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/configuration/ob-sam-data-bank"}>OB SAM Data Bank</Link>
        ),
        key: "/configuration/ob-sam-data-bank",
        to: "/configuration/ob-sam-data-bank",
        breadcrumbname: "ob-sam-data-bank",
        allowedpermissions: [
          OB_SAM_DATA_BANK_VIEW_PERMISSION,
          OB_SAM_DATA_BANK_CREATE_PERMISSION,
          OB_SAM_DATA_BANK_EDIT_PERMISSION,
          OB_SAM_DATA_BANK_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/configuration/ob-template"}>OB Template</Link>,
        key: "/configuration/ob-template",
        to: "/configuration/ob-template",
        breadcrumbname: "ob-template",
        allowedpermissions: [
          OB_TEMPLATE_VIEW_PERMISSION,
          OB_TEMPLATE_CREATE_PERMISSION,
          OB_TEMPLATE_EDIT_PERMISSION,
          OB_TEMPLATE_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/merchandising/operation-bulletin"}>
            Operation Bulletin{" "}
          </Link>
        ),
        key: "/merchandising/operation-bulletin",
        to: "/merchandising/operation-bulletin",
        allowedpermissions: [
          OB_VIEW_PERMISSION,
          OB_CREATE_PERMISSION,
          OB_EDIT_PERMISSION,
          OB_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <ShopOutlined />,
    label: "Inventory",
    key: "/inventory",
    children: [
      {
        label: (
          <Link to={"/inventory/store/fabric-store-entry"}>Fabric Store</Link>
        ),
        to: "/inventory/store/fabric-store-entry",
        key: "/inventory/store/fabric-store-entry",
        allowedpermissions: [
          INVENTORY_VIEW_PERMISSION,
          INVENTORY_CREATE_PERMISSION,
          INVENTORY_EDIT_PERMISSION,
          INVENTORY_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/inventory/store/trims-store-entry"}>
            Trims and Accessories Store
          </Link>
        ),
        to: "/inventory/store/trims-store-entry",
        key: "/inventory/store/trims-store-entry",
        allowedpermissions: [
          INVENTORY_VIEW_PERMISSION,
          INVENTORY_CREATE_PERMISSION,
          INVENTORY_EDIT_PERMISSION,
          INVENTORY_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/inventory/finish-good"}>Finish Good</Link>,
        to: "/inventory/finish-good",
        key: "/inventory/finish-good",
        allowedpermissions: [
          FINISH_GARMENT_VIEW_PERMISSION,
          FINISH_GARMENT_CREATE_PERMISSION,
          FINISH_GARMENT_EDIT_PERMISSION,
          FINISH_GARMENT_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/local-purchase-reconciliation">
            Local Purchase Reconciliation
          </Link>
        ),
        key: "/local-purchase-reconciliation",
        to: "/local-purchase-reconciliation",
        breadcrumbname: "Local Purchase Reconciliation",
        allowedpermissions: [
          LOCAL_PURCHASE_RECONCILIATION_VIEW_PERMISSION,
          LOCAL_PURCHASE_RECONCILIATION_CREATE_PERMISSION,
          LOCAL_PURCHASE_RECONCILIATION_EDIT_PERMISSION,
          LOCAL_PURCHASE_RECONCILIATION_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <ShoppingOutlined />,
    label: "Production",
    key: "/production",
    children: [
      {
        label: (
          <Link to={"/production/cutting"} replace={true}>
            Cutting
          </Link>
        ),
        to: "/production/cutting",
        key: "/production/cutting",
        allowedpermissions: [
          CUTTING_VIEW_PERMISSION,
          CUTTING_CREATE_PERMISSION,
          CUTTING_EDIT_PERMISSION,
          CUTTING_DELETE_PERMISSION,
        ],
      },
      // {
      //   label: (
      //     <Link to={"/production/cutting/cutting-item-entry"} replace={true}>
      //       Cutting Item
      //     </Link>
      //   ),
      //   to: "/production/cutting/cutting-item-entry",
      //   key: "/production/cutting/cutting-item-entry",
      //   allowedpermissions: [
      //     CUTTING_VIEW_PERMISSION,
      //     CUTTING_CREATE_PERMISSION,
      //     CUTTING_EDIT_PERMISSION,
      //     CUTTING_DELETE_PERMISSION,
      //   ],
      // },
      {
        label: <Link to={"/production/assignment"}>Assignment</Link>,
        to: "/production/assignment",
        key: "/production/assignment",
        allowedpermissions: [
          ASSIGNMENT_VIEW_PERMISSION,
          ASSIGNMENT_CREATE_PERMISSION,
          ASSIGNMENT_EDIT_PERMISSION,
          ASSIGNMENT_DELETE_PERMISSION,
        ],
      },
      // {
      //   label: <Link to={"/production/add-sewing"}>Sewing</Link>,
      //   to: "/production/sewing",
      //   key: "/production/add-sewing",
      //   allowedpermissions: [
      //     SEWING_VIEW_PERMISSION,
      //     SEWING_CREATE_PERMISSION,
      //     SEWING_EDIT_PERMISSION,
      //     SEWING_DELETE_PERMISSION,
      //   ],
      // },
      {
        label: <Link to={"/production/sewing-new"}>Sewing</Link>,
        to: "/production/sewing-new",
        key: "/production/sewing-new",
        allowedpermissions: [
          SEWING_VIEW_PERMISSION,
          SEWING_CREATE_PERMISSION,
          SEWING_EDIT_PERMISSION,
          SEWING_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/production/folding"}>Folding</Link>,
        key: "/production/folding",
        to: "/production/folding",
        allowedpermissions: [
          FOLDING_VIEW_PERMISSION,
          FOLDING_CREATE_PERMISSION,
          FOLDING_EDIT_PERMISSION,
          FOLDING_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/merchandising/packing-requirements"}>
            Packing Requirements
          </Link>
        ),
        key: "/merchandising/packing-requirements",
        to: "/merchandising/packing-requirements",
        allowedpermissions: [
          PACKING_VIEW_PERMISSION,
          PACKING_CREATE_PERMISSION,
          PACKING_EDIT_PERMISSION,
          PACKING_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/production/packing"}>Packing Confirmation</Link>,
        key: "/production/packing",
        to: "/production/packing",
        allowedpermissions: [
          PACKING_VIEW_PERMISSION,
          PACKING_CREATE_PERMISSION,
          PACKING_EDIT_PERMISSION,
          PACKING_DELETE_PERMISSION,
        ],
      },
      // {
      //   label: <Link to={"/production/packing/packing-entry"}>Packing</Link>,
      //   key: "/production/packing/packing-entry",
      //   to: "/production/packing/packing-entry",
      //   allowedpermissions: [
      //     PACKING_VIEW_PERMISSION,
      //     PACKING_CREATE_PERMISSION,
      //     PACKING_EDIT_PERMISSION,
      //     PACKING_DELETE_PERMISSION,
      //   ],
      // },
      {
        label: (
          <Link to={"/production/daily-line-target"}>Daily Line Target</Link>
        ),
        key: "/production/daily-line-target",
        to: "/production/daily-line-target",
        allowedpermissions: [
          DAILY_LINE_TARGET_VIEW_PERMISSION,
          DAILY_LINE_TARGET_CREATE_PERMISSION,
          DAILY_LINE_TARGET_EDIT_PERMISSION,
          DAILY_LINE_TARGET_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/production/fabric-accessories-auto-reconciliation"}>
            Fabric and Accessories Auto Reconciliation
          </Link>
        ),
        key: "/production/fabric-accessories-auto-reconciliation",
        to: "/production/fabric-accessories-auto-reconciliation",
        allowedpermissions: [
          FABRIC_ACCESSORIES_AUTO_RECONCILIATION_VIEW_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/production/post-cutting-process"}>
            Embroidery, Printing, Smocking and Pintuck
          </Link>
        ),
        key: "/production/post-cutting-process",
        to: "/production/post-cutting-process",
        allowedpermissions: [
          EMBROIDERY_PRINTING_SMOCKING_PINTUCK_VIEW_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/production/post-sewing-process"}>
            Washing and Snap Button
          </Link>
        ),
        key: "/production/post-sewing-process",
        to: "/production/post-sewing-process",
        allowedpermissions: [WASHING_SNAP_BUTTON_VIEW_PERMISSION],
      },
    ],
  },
  {
    icon: <DeliveredProcedureOutlined />,
    label: "Forwarding",
    key: "/forwarding",
    children: [
      {
        label: <Link to={"/forwarding/store-shipment-info"}>Shipment</Link>,
        key: "/forwarding/store-shipment-info",
        to: "/shipment",
        allowedpermissions: [
          SHIPPING_VIEW_PERMISSION,
          SHIPPING_CREATE_PERMISSION,
          SHIPPING_EDIT_PERMISSION,
          SHIPPING_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <FileDoneOutlined />,
    label: "Reports",
    key: "/report",
    children: [
      {
        label: (
          <Link to={"/report/report-fabric-reconciliation"}>
            Fabric Reconciliation
          </Link>
        ),
        key: "/report/report-fabric-reconciliation",
        to: "/report-fabric-reconciliation",
        allowedpermissions: [
          FABRIC_REPORT_VIEW_PERMISSION,
          FABRIC_REPORT_GENERATE_PERMISSION,
          FABRIC_REPORT_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/report/report-production-tracking"}>
            Production Tracking
          </Link>
        ),
        key: "/report/report-production-tracking",
        to: "/report-production-tracking",
        allowedpermissions: [
          PRODUCT_TRACK_REPORT_VIEW_PERMISSION,
          PRODUCT_TRACK_REPORT_GENERATE_PERMISSION,
          PRODUCT_TRACK_REPORT_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/report/report-production-details"}>
            Production Details
          </Link>
        ),
        key: "/report/report-production-details",
        to: "/report-production-details",
        allowedpermissions: [
          PRODUCT_DETAIL_REPORT_GENERATE_PERMISSION,
          PRODUCT_DETAIL_REPORT_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/report/report-cost-sheet"}>Cost Sheet</Link>,
        key: "/report/report-cost-sheet",
        to: "/report-cost-sheet",
        allowedpermissions: [
          COST_SHEET_REPORT_VIEW_PERMISSION,
          COST_SHEET_REPORT_GENERATE_PERMISSION,
          COST_SHEET_REPORT_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to={"/report/report-inventory-details"}>Inventory Details</Link>
        ),
        key: "/report/report-inventory-details",
        to: "/report-inventory-details",
        allowedpermissions: [
          INVENTORY_DETAIL_REPORT_VIEW_PERMISSION,
          INVENTORY_DETAIL_REPORT_GENERATE_PERMISSION,
          INVENTORY_DETAIL_REPORT_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to={"/report/report-inventory-mrr"}>Inventory MRR</Link>,
        key: "/report/report-inventory-mrr",
        to: "/report-inventory-mrr",
        allowedpermissions: [
          INVENTORY_MRR_REPORT_VIEW_PERMISSION,
          GENERATE_MRR_REPORT_VIEW_PERMISSION,
          DELETE_MRR_REPORT_VIEW_PERMISSION,
        ],
      },
      {
        label: <Link to={"/report/report-packing"}>Packing Report</Link>,
        key: "/report/report-packing",
        to: "/report-packing",
        allowedpermissions: [PACKING_LIST_REPORT_VIEW_PERMISSION],
      },
      {
        label: <Link to={"/report/hourly-production"}>Hourly Production</Link>,
        key: "/report/hourly-production",
        to: "/hourly-production",
        allowedpermissions: [HOURLY_PRODUCTION_VIEW_PERMISSION],
      },
      {
        label: <Link to={"/report/report-bolt-seal"}>Bolt Seal</Link>,
        key: "/report/report-bolt-seal",
        to: "/report/report-bolt-seal",
        allowedpermissions: [BOLT_SEAL_LOG_REPORT_PERMISSION],
      },
      {
        label: <Link to={"/report/report-post-costing"}>Post Costing</Link>,
        key: "/report/report-post-costing",
        to: "/report/report-post-costing",
        allowedpermissions: [POST_COSTING_REPORT_GENERATE_PERMISSION],
      },
      {
        label: <Link to={"/report/report-osr"}>OSR</Link>,
        key: "/report/report-osr",
        to: "/report-osr",
        allowedpermissions: [OSR_REPORT_GENERATE_PERMISSION],
      },
      {
        label: (
          <Link to={"/report/report-sewing-and-folding"}>Sewing & Folding</Link>
        ),
        key: "/report/report-sewing-and-folding",
        to: "/report-sewing-and-folding",
        allowedpermissions: [SEWING_FOLDING_REPORT_GENERATE_PERMISSION],
      },
      {
        label: (
          <Link to={"/report/sales-contract-pos"}>Sales Contract POS</Link>
        ),
        key: "/report/sales-contract-pos",
        to: "/report/sales-contract-pos",
        allowedpermissions: [VIEW_ALL_ACTIVE_POS_PERMISSION],
      },
    ],
  },
  {
    icon: <PoundOutlined />,
    label: "Cost Of Making",
    key: "/cost-of-making",
    children: [
      {
        label: (
          <Link to={"/cost-of-making/expense-calculation"}>
            Expense Data Calculation
          </Link>
        ),
        key: "/cost-of-making/expense-calculation",
        to: "/cm-expense-calculation",
        allowedpermissions: [
          COST_OF_MAKING_LIST,
          COST_OF_MAKING_CREATE,
          COST_OF_MAKING_EDIT,
          COST_OF_MAKING_DELETE,
        ],
      },
      {
        label: <Link to={"/cost-of-making/default-data"}>Default Data</Link>,
        key: "/cost-of-making/default-data",
        to: "/cm-default-data",
        allowedpermissions: [
          COST_OF_MAKING_LIST,
          COST_OF_MAKING_CREATE,
          COST_OF_MAKING_EDIT,
          COST_OF_MAKING_DELETE,
        ],
      },
    ],
  },
  {
    icon: <ExportOutlined />,
    label: "Export",
    key: "/export",
    children: [
      {
        label: (
          <Link to="/export/sales-contract">
            Sales Contract / Master LC (Commercial)
          </Link>
        ),
        key: "/export/sales-contract",
        to: "/export/sales-contract",
        breadcrumbname: "Sales Contract",
        allowedpermissions: [
          SALES_CONTRACT_COMMERCIAL_VIEW_PERMISSION,
          SALES_CONTRACT_COMMERCIAL_CREATE_PERMISSION,
          SALES_CONTRACT_COMMERCIAL_EDIT_PERMISSION,
          SALES_CONTRACT_COMMERCIAL_DELETE_PERMISSION,
        ],
      },
      {
        label: <Link to="/export/export-details">Export Details</Link>,
        key: "/export/export-details",
        to: "/export/export-details",
        breadcrumbname: "Export Details",
        allowedpermissions: [
          EXPORT_DETAILS_VIEW_PERMISSION,
          EXPORT_DETAILS_CREATE_PERMISSION,
          EXPORT_DETAILS_EDIT_PERMISSION,
          EXPORT_DETAILS_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/export/submission-realization">
            Submission Realization
          </Link>
        ),
        key: "/export/submission-realization",
        to: "/export/submission-realization",
        breadcrumbname: "Submission Realization",
        allowedpermissions: [
          SUBMISSION_REALIZATION_VIEW_PERMISSION,
          SUBMISSION_REALIZATION_CREATE_PERMISSION,
          SUBMISSION_REALIZATION_EDIT_PERMISSION,
          SUBMISSION_REALIZATION_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <ImportOutlined />,
    label: "Import",
    key: "/import",
    children: [
      {
        label: <Link to="/import/b2blc">Import LC - BTB LC</Link>,
        key: "/import/b2blc",
        to: "/import/b2blc",
        breadcrumbname: "Import LC - BTB LC",
        allowedpermissions: [
          B2BLC_VIEW_PERMISSION,
          B2BLC_CREATE_PERMISSION,
          B2BLC_EDIT_PERMISSION,
          B2BLC_DELETE_PERMISSION,
        ],
      },
      {
        label: "Import Shipment Status",
        key: "/import/details",
        to: "/import/details",
        breadcrumbname: "Sales Contract",
        allowedpermissions: [
          DAILY_IMPORT_POSITION_VIEW_PERMISSION,
          DAILY_IMPORT_POSITION_CREATE_PERMISSION,
          DAILY_IMPORT_POSITION_EDIT_PERMISSION,
          DAILY_IMPORT_POSITION_DELETE_PERMISSION,
        ],
        children: [
          {
            label: <Link to="/import/details/0">Foreign</Link>,
            key: "/import/details/0",
            to: "/import/details/0",
            breadcrumbname: "Foreign",
          },
          {
            label: <Link to="/import/details/1">Local</Link>,
            key: "/import/details/1",
            to: "/import/details/1",
            breadcrumbname: "Local",
          },
          {
            label: <Link to="/import/details/2">EPZ</Link>,
            key: "/import/details/2",
            to: "/import/details/2",
            breadcrumbname: "EPZ",
          },
        ],
      },
      {
        label: (
          <Link to="/import/acceptance-details">Import Acceptance Details</Link>
        ),
        key: "/import/acceptance-details",
        to: "/import/acceptance-details",
        breadcrumbname: "Sales Contract",
        allowedpermissions: [
          IMPORT_ACCEPTANCE_DETAIL_VIEW_PERMISSION,
          IMPORT_ACCEPTANCE_DETAIL_CREATE_PERMISSION,
          IMPORT_ACCEPTANCE_DETAIL_EDIT_PERMISSION,
          IMPORT_ACCEPTANCE_DETAIL_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <CarryOutOutlined />,
    label: "Import Customs Clearance",
    key: "/import-customs-clearance",
    children: [
      {
        label: (
          <Link to="/import-customs-clearance/noting-assessment-examination">
            Noting Assessment and Examinations
          </Link>
        ),
        key: "/import-customs-clearance/noting-assessment-examination",
        to: "/import-customs-clearance/noting-assessment-examination",
        breadcrumbname: "Noting Assessment and Examinations",
        allowedpermissions: [
          NOTING_ASSESSMENT_VIEW_PERMISSION,
          NOTING_ASSESSMENT_CREATE_PERMISSION,
          NOTING_ASSESSMENT_EDIT_PERMISSION,
          NOTING_ASSESSMENT_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/import/goods-delivery">
            Import Shipment Vehicle Status
          </Link>
        ),
        key: "/import/goods-delivery",
        to: "/import/goods-delivery",
        breadcrumbname: "Import Shipment Vehicle Status",
        allowedpermissions: [
          GOODS_DELIVERY_VIEW_PERMISSION,
          GOODS_DELIVERY_CREATE_PERMISSION,
          GOODS_DELIVERY_EDIT_PERMISSION,
          GOODS_DELIVERY_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <CloseSquareOutlined />,
    label: "Ex-Factory",
    key: "/ex-factory",
    children: [
      // {
      //   label: (
      //     <Link to="/ex-factory/security-bolt-seal-log">
      //       Security Bolt Seal Log
      //     </Link>
      //   ),
      //   key: "/ex-factory/security-bolt-seal-log",
      //   to: "/ex-factory/security-bolt-seal-log",
      //   breadcrumbname: "Security Bolt Seal Log",
      //   allowedpermissions: [
      //     SECURITY_BOLT_VIEW_PERMISSION,
      //     SECURITY_BOLT_CREATE_PERMISSION,
      //     SECURITY_BOLT_EDIT_PERMISSION,
      //     SECURITY_BOLT_DELETE_PERMISSION,
      //   ],
      // },

      {
        label: (
          <Link to="/ex-factory/vehicle-information">Vehicle Information</Link>
        ),
        key: "/ex-factory/vehicle-information",
        to: "/ex-factory/vehicle-information",
        breadcrumbname: "Vehicle Information",
        allowedpermissions: [
          DRIVER_VEHICLE_INFO_VIEW_PERMISSION,
          DRIVER_VEHICLE_INFO_CREATE_PERMISSION,
          DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
          DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
          DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/ex-factory/driver-information">Driver Information</Link>
        ),
        key: "/ex-factory/driver-information",
        to: "/ex-factory/driver-information",
        breadcrumbname: "Driver Information",
        allowedpermissions: [
          DRIVER_VEHICLE_INFO_VIEW_PERMISSION,
          DRIVER_VEHICLE_INFO_CREATE_PERMISSION,
          DRIVER_VEHICLE_INFO_EDIT_PERMISSION,
          DRIVER_VEHICLE_INFO_DELETE_PERMISSION,
          DRIVER_VEHICLE_INFO_DISPATCH_TIME_PERMISSION,
        ],
      },
      {
        label: <Link to="/ex-factory/bolt-seal-log">Bolt Seal Log</Link>,
        key: "/ex-factory/bolt-seal-log",
        to: "/ex-factory/bolt-seal-log",
        breadcrumbname: "Bolt Seal Log",
        allowedpermissions: [
          BOLT_SEAL_LOG_CREATE_PERMISSION,
          BOLT_SEAL_LOG_EDIT_PERMISSION,
          BOLT_SEAL_LOG_VIEW_PERMISSION,
          BOLT_SEAL_LOG_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <CiOutlined />,
    label: "Export Customs Clearance",
    key: "/export-customs-clearance",
    children: [
      {
        label: (
          <Link to="/export-customs-clearance/shipment-handover">
            Shipment Handover
          </Link>
        ),
        key: "/export-customs-clearance/shipment-handover",
        to: "/export-customs-clearance/shipment-handover",
        breadcrumbname: "Shipment Handover",
        allowedpermissions: [
          SHIPMENT_HANDOVER_VIEW_PERMISSION,
          SHIPMENT_HANDOVER_CREATE_PERMISSION,
          SHIPMENT_HANDOVER_EDIT_PERMISSION,
          SHIPMENT_HANDOVER_DELETE_PERMISSION,
        ],
      },
    ],
  },
  {
    icon: <DiffOutlined />,
    label: <Link to="/import-files">Import Files</Link>,
    key: "/import-files",
    to: "/import-files",
    breadcrumbname: "Import Files",
    allowedpermissions: [
      UPLOAD_BUYER_PERMISSION,
      UPLOAD_BRAND_PERMISSION,
      UPLOAD_BUYING_OFFICE_PERMISSION,
      UPLOAD_BANK_PERMISSION,
      UPLOAD_BRANCH_PERMISSION,
      UPLOAD_MERCHANDISER_PERMISSION,
      UPLOAD_STYLE_PERMISSION,
      UPLOAD_SUBSTYLE_PERMISSION,
      UPLOAD_SALES_CONTRACT_PERMISSION,
      UPLOAD_SUPPLIER_PERMISSION,
      UPLOAD_COLOR_PERMISSION,
      UPLOAD_SIZE_PERMISSION,
      UPLOAD_BOM_PERMISSION,
      UPLOAD_PO_PERMISSION,
    ],
  },
  {
    icon: <BellOutlined />,
    label: <Link to="/notifications">Notifications</Link>,
    key: "/notifications",
    to: "/notifications",
    breadcrumbname: "Notifications",
    allowedpermissions: [NO_PERMISSION_REQUIRED],
  },
  {
    icon: <LoginOutlined />,
    label: "Activity Log",
    key: "/activity-log",
    children: [
      {
        label: <Link to="/activity-log/all">All Activity</Link>,
        key: "/activity-log/all",
        to: "/activity-log/all",
        breadcrumbname: "All Activity",
        allowedpermissions: [ACTIVITY_LOG_VIEW_PERMISSION],
      },
    ],
  },
  {
    icon: <AppstoreAddOutlined />,
    label: "Digitalization",
    key: "/digitalization",
    allowedpermissions: [DIGITALIZATION_MENU_VIEW_PERMISSION],
    children: [
      {
        label: <Link to="/digitalization/app-dashboard">App Dashboard</Link>,
        key: "/digitalization/app-dashboard",
        to: "/digitalization/app-dashboard",
        breadcrumbname: "App Dashboard",
        allowedpermissions: [APP_DASHBOARD_VIEW_PERMISSION],
      },

      {
        label: (
          <Link to="/digitalization/supervisor-checklist">
            Supervisor Checklist
          </Link>
        ),
        key: "/digitalization/supervisor-checklist",
        to: "/digitalization/supervisor-checklist",
        breadcrumbname: "Supervisor Checklist",
        allowedpermissions: [
          SUPERVISOR_CHECKLIST_CREATE_PERMISSION,
          SUPERVISOR_CHECKLIST_EDIT_PERMISSION,
          SUPERVISOR_CHECKLIST_VIEW_PERMISSION,
          SUPERVISOR_CHECKLIST_DELETE_PERMISSION,
        ],
      },
      {
        label: (
          <Link to="/digitalization/operator-absenteeism">
            Operator Absenteeism
          </Link>
        ),
        key: "/digitalization/operator-absenteeism",
        to: "/digitalization/operator-absenteeism",
        breadcrumbname: "Operator Absenteeism",
        allowedpermissions: [DIGITALIZATION_ABSENTEEISM_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to="/digitalization/production-defect">Production Defect</Link>
        ),
        key: "/digitalization/production-defect",
        to: "/digitalization/production-defect",
        breadcrumbname: "Production Defect",
        allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to="/digitalization/hourly-production-record">
            Hourly Production Record
          </Link>
        ),
        key: "/digitalization/hourly-production-record",
        to: "/digitalization/hourly-production-record",
        breadcrumbname: "Hourly Production Record",
        allowedpermissions: [HOURLY_PRODUCTION_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to="/digitalization/garments-tracking">Garments Tracking</Link>
        ),
        key: "/digitalization/garments-tracking",
        to: "/digitalization/garments-tracking",
        breadcrumbname: "Garments Tracking",
        allowedpermissions: [GARMENTS_TRACKING_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to="/digitalization/daily-line-plan">Daily Line Plan</Link>
        ),
        key: "/digitalization/daily-line-plan",
        to: "/digitalization/daily-line-plan",
        breadcrumbname: "Daily Line Plan",
        allowedpermissions: [DAILY_LINE_PLAN_VIEW_PERMISSION],
      },
      {
        label: "Settings",
        key: "/Settings",
        to: "Settings",
        breadcrumbname: "Settings",
        allowedpermissions: [DIGITALIZATION_SETTINGS_VIEW_PERMISSION],
        children: [
          {
            label: <Link to="/digitalization/app-role">Menu</Link>,
            key: "/digitalization/app-role",
            to: "/digitalization/app-role",
            breadcrumbname: "Menu Setting",
            allowedpermissions: [DIGITALIZATION_SETTINGS_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/user-line-assign">
                User Line Assign
              </Link>
            ),
            key: "/digitalization/user-line-assign",
            to: "/digitalization/user-line-assign",
            breadcrumbname: "User Line Assign",
            allowedpermissions: [DIGITALIZATION_SETTINGS_VIEW_PERMISSION],
          },
          {
            label: <Link to="/digitalization/user-list">User</Link>,
            key: "/digitalization/user-list",
            to: "/digitalization/user-list",
            breadcrumbname: "User List",
            allowedpermissions: [DIGITALIZATION_SETTINGS_VIEW_PERMISSION],
          },
          {
            label: <Link to="/digitalization/hour">Hour</Link>,
            key: "/digitalization/hour",
            to: "/digitalization/hour",
            breadcrumbname: "Hour",
            allowedpermissions: [DIGITALIZATION_SETTINGS_VIEW_PERMISSION],
          },
        ],
      },
      {
        label: "Defect",
        key: "/defect",
        to: "/defect",
        breadcrumbname: "Defect",
        allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
        children: [
          {
            label: <Link to="/digitalization/defect/category">Category</Link>,
            key: "/digitalization/defect/category",
            to: "/digitalization/defect/category",
            breadcrumbname: "Category",
            allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
          },
          {
            label: <Link to="/digitalization/defect/reason">Reason</Link>,
            key: "/digitalization/defect/reason",
            to: "/digitalization/defect/reason",
            breadcrumbname: "Reason",
            allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/defect/responsible">Responsible</Link>
            ),
            key: "/digitalization/defect/responsible",
            to: "/digitalization/defect/responsible",
            breadcrumbname: "Responsible",
            allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/defect/recommendation">
                Recommendation
              </Link>
            ),
            key: "/digitalization/defect/recommendation",
            to: "/digitalization/defect/recommendation",
            breadcrumbname: "Recommendation",
            allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/defect/category-mapping">
                Category Mapping
              </Link>
            ),
            key: "/digitalization/defect/category-mapping",
            to: "/digitalization/defect/category-mapping",
            breadcrumbname: "Category-mapping",
            allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/defect/line-and-section-mapping">
                Line & Section wise Mapping
              </Link>
            ),
            key: "digitalization/defect/line-and-section-mapping",
            to: "digitalization/defect/line-and-section-mapping",
            breadcrumbname: "Line-and-section-mapping",
            allowedpermissions: [DIGITALIZATION_DEFECT_VIEW_PERMISSION],
          },
        ],
      },
      {
        label: "PP and Buyer Inspection",
        key: "/pp-and-buyer-inspection",
        to: "/pp-and-buyer-inspection",
        breadcrumbname: "PP and Buyer Inspection",
        allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
        children: [
          {
            label: (
              <Link to="/digitalization/pp-meeting-date">PP Meeting Date</Link>
            ),
            key: "/digitalization/pp-meeting-date",
            to: "/digitalization/pp-meeting-date",
            breadcrumbname: "PP Meeting Date",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/buyer-inspection-date">
                Buyer Inspection Date
              </Link>
            ),
            key: "/digitalization/buyer-inspection-date",
            to: "/digitalization/buyer-inspection-date",
            breadcrumbname: "Buyer Inspection Date",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/buyer-visit-date">
                Buyer Visit Date
              </Link>
            ),
            key: "/digitalization/buyer-visit-date",
            to: "/digitalization/buyer-visit-date",
            breadcrumbname: "Buyer Visit Date",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: <Link to="/digitalization/audit-date">Audit Date</Link>,
            key: "/digitalization/audit-date",
            to: "/digitalization/audit-date",
            breadcrumbname: "Audit Date",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/capacity-of-sample-team">
                Capacity of Sample Team
              </Link>
            ),
            key: "/digitalization/capacity-of-sample-team",
            to: "/digitalization/capacity-of-sample-team",
            breadcrumbname: "Capacity of Sample Team",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/fabric-loading-capacity-of-store">
                Fabric Loading Capacity of Store
              </Link>
            ),
            key: "/digitalization/fabric-loading-capacity-of-store",
            to: "/digitalization/fabric-loading-capacity-of-store",
            breadcrumbname: "Fabric Loading Capacity of Store",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/sample-requisitions">
                Sample Requisitions
              </Link>
            ),
            key: "/digitalization/sample-requisitions",
            to: "/digitalization/sample-requisitions",
            breadcrumbname: "Sample Requisitions",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/overtime-reasons">
                Overtime Reasons
              </Link>
            ),
            key: "/digitalization/overtime-reasons",
            to: "/digitalization/overtime-reasons",
            breadcrumbname: "Overtime Reasons",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/overtime-signals">
                Overtime Signals
              </Link>
            ),
            key: "/digitalization/overtime-signals",
            to: "/digitalization/overtime-signals",
            breadcrumbname: "Overtime Signals",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
          {
            label: (
              <Link to="/digitalization/sam-justification">
                Sam Justification
              </Link>
            ),
            key: "/digitalization/sam-justification",
            to: "/digitalization/sam-justification",
            breadcrumbname: "Sam Justification",
            allowedpermissions: [PP_BUYER_INSPECTION_MENU_VIEW_PERMISSION],
          },
        ],
      },
      {
        label: "Checklist",
        key: "/checklist",
        to: "/checklist",
        breadcrumbname: "Checklist",
        allowedpermissions: [CHECKLIST_MENU_VIEW_PERMISSION],
        children: [
          {
            label: (
              <Link to="/checklist/checklist-questions/list">
                Questions List
              </Link>
            ),
            key: "/checklist/checklist-questions/list",
            to: "/checklist/checklist-questions/list",
            breadcrumbname: "Category",
            allowedpermissions: [CHECKLIST_MENU_VIEW_PERMISSION],
          },
        ],
      },
      {
        label: (
          <Link to="/digitalization/buyer-wise-checklist">
            Buyer Wise Checklist
          </Link>
        ),
        key: "/digitalization/buyer-wise-checklist",
        to: "/digitalization/buyer-wise-checklist",
        breadcrumbname: "Buyer Style Checklists",
        allowedpermissions: [BUYER_WISE_CHECKLIST_VIEW_PERMISSION],
      },
    ],
  },
  {
    icon: <ShopOutlined />,
    label: "Capacity Bookings",
    key: "/capacity-bookings",
    to: "/capacity-bookings",
    breadcrumbname: "Capacity Bookings",
    allowedpermissions: [CAPACITY_BOOKING_MENU_VIEW_PERMISSION],
    children: [
      {
        label: <Link to="/capacity-bookings/report">Report</Link>,
        key: "/capacity-bookings/report",
        to: "/capacity-bookings/report",
        breadcrumbname: "Capacity Bookings Report",
        allowedpermissions: [CAPACITY_BOOKING_MENU_VIEW_PERMISSION],
      },
      {
        label: <Link to="/capacity-bookings">Settings</Link>,
        key: "/capacity-bookings",
        to: "/capacity-bookings",
        breadcrumbname: "Capacity Bookings",
        allowedpermissions: [CAPACITY_BOOKING_MENU_VIEW_PERMISSION],
      },
    ],
  },
  {
    icon: <ShopOutlined />,
    label: "ERP Usage",
    key: "erp-usage",
    to: "/erp-usage",
    breadcrumbname: "ERP Usage",
    allowedpermissions: [ERP_USAGE_MODULE_VIEW_PERMISSION],
    children: [
      {
        label: <Link to="/erp-usage/dashboard">Dashboard</Link>,
        key: "/erp-usage/dashboard",
        to: "/erp-usage/dashboard",
        breadcrumbname: "ERP Usage Dashboard",
        allowedpermissions: [ERP_USAGE_MODULE_VIEW_PERMISSION],
      },
      {
        label: <Link to="/erp-usage/modules">Modules</Link>,
        key: "/erp-usage/modules",
        to: "/erp-usage/modules",
        breadcrumbname: "ERP Usage Modules",
        allowedpermissions: [ERP_USAGE_MODULE_VIEW_PERMISSION],
      },
      {
        label: <Link to="/erp-usage/module-uis">UIs</Link>,
        key: "/erp-usage/module-uis",
        to: "/erp-usage/module-uis",
        breadcrumbname: "ERP Usage Module UIs",
        allowedpermissions: [ERP_USAGE_MODULE_VIEW_PERMISSION],
      },
      {
        label: <Link to="/erp-usage/issues">Issues</Link>,
        key: "/erp-usage/issues",
        to: "/erp-usage/issues",
        breadcrumbname: "ERP Usage Issues",
        allowedpermissions: [ERP_USAGE_MODULE_VIEW_PERMISSION],
      },
    ],
  },
  {
    icon: <ShopOutlined />,
    label: "PNL Report",
    key: "PNL Report",
    to: "/pnl-report",
    breadcrumbname: "PNL Report",
    allowedpermissions: [PNL_REPORT_VIEW_PERMISSION],
    children: [
      {
        label: (
          <Link to="/pnl-report/daily-sewing-performance">
            Daily Sewing Performance
          </Link>
        ),
        key: "/pnl-report/daily-sewing-performance",
        to: "/pnl-report/daily-sewing-performance",
        breadcrumbname: "Daily Sewing Performance",
        allowedpermissions: [DAILY_SEWING_PERFORMANCE_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to="/pnl-report/he-ot-expense-data">HR OT Expense Data</Link>
        ),
        key: "/pnl-report/he-ot-expense-data",
        to: "/pnl-report/he-ot-expense-data",
        breadcrumbname: "HR OT Expense Data",
        allowedpermissions: [HR_OT_EXPENSE_DATA_IMPORT_PERMISSION],
      },
      {
        label: (
          <Link to="/pnl-report/line-wise-sewing-report">
            Line Wise Sewing Report
          </Link>
        ),
        key: "/pnl-report/line-wise-sewing-report",
        to: "/pnl-report/line-wise-sewing-report",
        breadcrumbname: "Line Wise Sewing Report",
        allowedpermissions: [LINE_WISE_SEWING_REPORT_VIEW_PERMISSION],
      },
      {
        label: (
          <Link to="/pnl-report/ipr-mmd">Individual Performance Report</Link>
        ),
        key: "/pnl-report/ipr-mmd",
        to: "/pnl-report/ipr-mmd",
        breadcrumbname: "Individual Performance Report",
        allowedpermissions: [IPR_REPORT_VIEW_PERMISSION],
      },
      {
        label: <Link to="/pnl-report/efficiency">Efficiency</Link>,
        key: "/pnl-report/efficiency",
        to: "/pnl-report/efficiency",
        breadcrumbname: "efficiency",
        allowedpermissions: [NO_PERMISSION_REQUIRED],
      },
      {
        label: (
          <Link to="/pnl-report/target-vs-achievement">
            Target Vs Achievement
          </Link>
        ),
        key: "/pnl-report/target-vs-achievement",
        to: "/pnl-report/target-vs-achievement",
        breadcrumbname: "Target Vs Achievement",
        allowedpermissions: [NO_PERMISSION_REQUIRED],
      },
      {
        label: <Link to="/pnl-report/cpm">CPM</Link>,
        key: "/pnl-report/cpm",
        to: "/pnl-report/cpm",
        breadcrumbname: "CPM",
        allowedpermissions: [NO_PERMISSION_REQUIRED],
      },
      {
        label: (
          <Link to="/pnl-report/daily-profit-loss-summary">
            Profit & Loss Summary
          </Link>
        ),
        key: "/pnl-report/daily-profit-loss-summary",
        to: "/pnl-report/daily-profit-loss-summary",
        breadcrumbname: "Daily Profit & Loss Summary",
        allowedpermissions: [NO_PERMISSION_REQUIRED],
      },
      {
        label: (
          <Link to="/pnl-report/profit-loss-account">
            Profit & Loss Account
          </Link>
        ),
        key: "/pnl-report/profit-loss-account",
        to: "/pnl-report/profit-loss-account",
        breadcrumbname: "Daily Profit & Loss Account",
        allowedpermissions: [NO_PERMISSION_REQUIRED],
      },
    ],
  },
];

// Tokens
const accessToken = localStorage.getItem("token");
const roleInfo =
  localStorage.getItem("roleInfo") &&
  JSON.parse(localStorage.getItem("roleInfo"));
const assignedPermissions = roleInfo ? roleInfo?.permissions : null;

// Function to check if a menu item is allowed or not
const hasPermission = (allowedpermissions) => {
  const hasRolePermission =
    assignedPermissions &&
    assignedPermissions?.find((permissionItem) =>
      allowedpermissions.includes(permissionItem?.name),
    );

  // If has this page access
  if (accessToken && hasRolePermission) {
    return true;
  }

  // If the route does not require permissions
  if (accessToken && allowedpermissions.includes(NO_PERMISSION_REQUIRED)) {
    return true;
  }

  // // Else no access
  return false;
};

const filterMenuByPermissions = (menu, assignedPermissions) => {
  return menu.filter((item) => {
    if (item?.allowedpermissions && item?.allowedpermissions?.length > 0) {
      // Check if menu item is allowed
      const permissionAllowed = hasPermission(item.allowedpermissions);
      if (!permissionAllowed) {
        return false;
      }
    }

    // Recursive permission check on menu children items
    if (item.children) {
      item.children = filterMenuByPermissions(
        item.children,
        assignedPermissions,
      );

      // Check if any child has permission
      if (item?.children?.length === 0) {
        // No child has permission, do not show the parent menu
        return false;
      }
    }

    // If a menu items comes this far, it is allowed
    return true;
  });
};

export const menu = filterMenuByPermissions(
  defaultMenuItems,
  assignedPermissions,
);
