import { Form } from "antd";
import {
  GET_INQUIRY_STYLE_DETAILS,
  GET_LINE_LIST,
  GENERATE_PLANNING_DETAILS,
  REGENERATE_PLAN,
  PLAN_INITIATE,
  BUYER_INQUIRY_LIST_ENDPOINT,
  GET_STYLE_LIST_ENDPOINT,
  SUBSTYLE_LIST,
} from "apiServices/API_ENDPOINTS";
import { deleteData, getData, postData, putData } from "apiServices/common";
import HandleNotification from "common/Notification";
import moment from "moment";
import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { isArrayAndHasValue } from "utils/functions";

export const ProductionPlanningContext = createContext();

const order_status = {
  0: "Inquiry",
  1: "Contracted",
  2: "Closed",
};

const ProductionPlanningContextProvider = ({ children }) => {
  // States
  const [planningDetails, setPlanningDetails] = useState(null);
  const [existingPlan, setExistingPlan] = useState(null);
  const [overlapPlans, setOverlapPlans] = useState(null);
  const [planningOverlapping, setPlanningOverlapping] = useState([{}]);
  const [inquiryStyleDetails, setInquiryStyleDetails] = useState({});
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [options, setOptions] = useState({
    lines: [],
    inquiryList: [],
    styleList: [],
    subStyleList: [],
    po: [],
  });
  const [createdWith, setCreatedWith] = useState(null);
  const [changeStatus, setChangeStatus] = useState(null);

  // Antd
  const [form] = Form.useForm();
  const inquiry_id_watch = Form.useWatch("inquiry_id", form);
  const style_id_watch = Form.useWatch("style_id", form);
  const sales_contract_po_id_watch = Form.useWatch(
    "sales_contract_po_id",
    form,
  );

  // router
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const line_id = searchParams.get("line_id");
  const start_date = searchParams.get("start_date");

  // get GET_LINE_LIST
  const getLineList = useCallback(async (filterValues) => {
    try {
      const payload = {
        ...filterValues,
      };
      const response = await getData(GET_LINE_LIST, false, payload);
      if (response.status === 200) {
        const lines = response.data?.data?.data?.map((line) => ({
          value: line.id,
          label: line.name,
        }));
        setOptions((prev) => ({ ...prev, lines }));
      }
    } catch (error) {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting line data",
        null,
      );
    }
  }, []);

  // Get inquiry list data
  const getInquiryList = useCallback(async (filterValues) => {
    try {
      const bodyData = {
        ...filterValues,
        per_page: filterValues?.per_page || 10,
      };
      const response = await getData(
        BUYER_INQUIRY_LIST_ENDPOINT,
        false,
        bodyData,
      );

      if (response && response?.data?.code === 200) {
        const inquiryList = response?.data?.data?.data;
        setOptions((prev) => ({ ...prev, inquiryList }));
      }
    } catch (error) {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting inquiry data",
        null,
      );
    }
  }, []);

  // Get style list data
  const getStyleList = useCallback(async (filterValues) => {
    try {
      const bodyData = {
        ...filterValues,
        per_page: filterValues?.per_page || 10,
        active_sales_contract: true,
      };
      const response = await getData(GET_STYLE_LIST_ENDPOINT, false, bodyData);

      if (response && response?.data?.code === 200) {
        const styleList = response?.data?.data?.data;
        setOptions((prev) => ({ ...prev, styleList }));
      }
    } catch (error) {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting style data",
        null,
      );
    }
  }, []);

  // Get sub style list data
  const getSubStyleList = useCallback(async (filterValues) => {
    try {
      const bodyData = {
        ...filterValues,
        per_page: filterValues?.per_page || 10,
      };
      const response = await getData(SUBSTYLE_LIST, false, bodyData);

      if (response && response?.data?.code === 200) {
        const subStyleList = response?.data?.data?.data;
        setOptions((prev) => ({ ...prev, subStyleList }));
      }
    } catch (error) {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting sub style data",
        null,
      );
    }
  }, []);

  // First Time render set the options
  useEffect(() => {
    form.setFieldsValue({
      apply_learning_curve: true,
      line: parseInt(line_id) || null,
      start_date: start_date ? moment(start_date) : null,
    });
    getLineList({ per_page: 10000 });
    getInquiryList();
    getStyleList();
    getSubStyleList();
  }, [
    getLineList,
    getInquiryList,
    getStyleList,
    getSubStyleList,
    form,
    line_id,
    start_date,
  ]);

  const getStyleId = (props) => {
    const { sub_style_id_prop, style_id_prop, options } = props;

    if (style_id_prop) {
      return style_id_prop;
    }

    const subStyleList = isArrayAndHasValue(options?.subStyleList)
      ? [...options?.subStyleList]
      : [];
    const foundSubStyle = subStyleList?.find(
      (item) => item?.id === sub_style_id_prop,
    );
    const foundStyleId = foundSubStyle?.style_id || null;

    return foundStyleId;
  };

  // on change Inquiry & Style
  const onInquiryAndStyleChange = useCallback(
    async (props, from) => {
      const {
        line_id_prop,
        inquiry_id_prop,
        style_id_prop,
        sub_style_id_prop,
        po_map_id,
        options,
      } = props;
      try {
        const line_id = form.getFieldValue("line");
        const sub_style_id = form.getFieldValue("sub_style_id");

        let payload = {
          line_id: line_id || line_id_prop,
          po_map_id: po_map_id || null,
        };

        if (inquiry_id_prop) {
          payload = {
            ...payload,
            inquiry_id: inquiry_id_prop,
          };
        }
        if (style_id_prop || sub_style_id_prop) {
          payload = {
            ...payload,
            style_id: getStyleId({ sub_style_id_prop, style_id_prop, options }),
            sub_style_id: sub_style_id_prop || sub_style_id,
          };
        }

        const API_ENDPOINTS = `${GET_INQUIRY_STYLE_DETAILS}`;
        const response = await getData(API_ENDPOINTS, false, payload);

        if (response.status === 200) {
          const data = response.data?.data;
          const obData = data?.operation_bulletin_info;
          const existing_plan_data = data?.existing_plan;

          setExistingPlan(
            isArrayAndHasValue(existing_plan_data) ? existing_plan_data : null,
          );

          if (obData?.message) {
            HandleNotification("warning", "bottomRight", obData?.message, null);
          }
          if (!obData) {
            HandleNotification(
              "error",
              "bottomRight",
              "OB data not found",
              null,
            );
          }

          // set po options
          setOptions((prev) => ({
            ...prev,
            po: data?.sales_contract_po?.map((po) => ({
              ...po,
              value: po?.id,
              label: po?.original_po,
            })),
          }));
          const dataMake = {
            ...data,
          };

          if (from !== "edit_mode") {
            // check inquiry selected or not
            if (data?.inquiry_details && data?.inquiry_details?.id) {
              // If inquiry status is Contracted (1)
              if (data?.inquiry_details?.inquiry_status === 1) {
                // If any PO is selected
                if (sales_contract_po_id_watch) {
                  // In this case, shipment date will come from selected PO
                  dataMake.shipment_date = null;
                } else {
                  dataMake.shipment_date = data?.inquiry_details
                    ?.expected_delivery_date
                    ? moment(data?.inquiry_details?.expected_delivery_date)
                    : null;
                }
              }
              // If inquiry status is Inquiry (0)
              if (data?.inquiry_details?.inquiry_status === 0) {
                dataMake.shipment_date = data?.inquiry_details
                  ?.expected_delivery_date
                  ? moment(data?.inquiry_details?.expected_delivery_date)
                  : null;
              }
            } else {
              // inquiry not found
              // shipment_date (pcd)set if po_map_id is selected and have shipment_date
              if (po_map_id && isArrayAndHasValue(data?.sales_contract_po)) {
                // find selected po from po list
                const selectedPo = data?.sales_contract_po.find(
                  (item) => item?.id === po_map_id,
                );
                dataMake.shipment_date = selectedPo?.delivery_date
                  ? moment(selectedPo?.delivery_date)
                  : null;
              } else {
                // When inquiry not found
                dataMake.shipment_date = null;
              }
            }
          }

          if (from === "edit_mode") {
            // For edit mode, no_of_workers will be used from show API
            delete dataMake["no_of_workers"];
          }

          // set form data
          form.setFieldsValue({
            ...dataMake,
            order_status_name: order_status[parseInt(data.order_status)],
            ob_id: obData?.id,
            use_both_style_po: data?.sales_contract?.use_both_style_po,
            inquiry_id: dataMake?.is_inquiry_id
              ? dataMake?.inquiry_details?.id
              : null,
            inquiry_id_when_not_selected: !dataMake?.is_inquiry_id
              ? dataMake?.inquiry_details?.id
              : null,
            // planned_qty: dataMake?.plan_qty,
          });
          // set inquiry style details
          setInquiryStyleDetails((prev) => ({ ...prev, ...dataMake }));
        }
      } catch (error) {
        HandleNotification("error", "bottomRight", "Error getting data", null);
      }
    },
    [form],
  );

  const shipmentDateSetter = (dateString) => {
    if (!dateString) return;

    if (inquiryStyleDetails?.inquiry_details?.id) {
      // If there is inquiry and inquiry status is Contracted (1)
      console.log("if 1");

      if (inquiryStyleDetails?.inquiry_details?.inquiry_status === 1) {
        console.log("if 2");
        form.setFieldsValue({
          shipment_date: moment(dateString),
        });
      }
    } else {
      // If inquiry not found
      // Style is active sales contract
      form.setFieldsValue({
        shipment_date: moment(dateString),
      });
    }
  };

  const onGenerate = useCallback(
    async (values) => {
      const payload = {
        line_id: values.line || null,
        inquiry_id:
          values?.inquiry_id || values?.inquiry_id_when_not_selected || null,
        style_id: values?.style_id || null,
        ob_id: values.ob_id || null,
        start_date: values.start_date
          ? moment(values.start_date).format("YYYY-MM-DD")
          : null,
        sales_contact_id: values.sales_contract.id || null,
        order_qty: values?.order_qty,
        update_id: values?.update_id || null,
        created_with: createdWith || null,
        po_map_id: values?.sales_contract_po_id || null,
      };

      const query = `${GENERATE_PLANNING_DETAILS}`;
      const response = await getData(query, false, payload);

      if (response && response?.data?.code === 200) {
        const planningData = response?.data?.data?.planning_details?.days;
        const customPlanningData =
          isArrayAndHasValue(planningData) &&
          planningData?.map((item) => {
            return {
              ...item,
              plan_curve_mh: item?.working_hours,
              plan_curve_efficiency: item?.efficiency,
              plan_curve_qty: item?.dayQty,
            };
          });

        if (!isArrayAndHasValue(planningData)) {
          HandleNotification(
            "error",
            "bottomRight",
            "Planning data not found",
            null,
          );
          return;
        }

        setPlanningDetails(customPlanningData);
        setExistingPlan(response?.data?.data?.existing_plan);
        setOverlapPlans(response?.data?.data?.overlap_plans);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error generating data",
          null,
        );
      }
    },
    [createdWith],
  );

  const calculateRemainingQty = (data) => {
    const { order_qty, plan_qty, actual_qty, start_date } = data;
    const planQty = plan_qty || 0;
    const actualQty = actual_qty || 0;
    const current_date = moment();
    const isPlanGreaterCurrentDate = moment(start_date).isAfter(current_date);
    const isCurrentGreaterEqualPlanDate =
      moment(current_date).isSameOrAfter(start_date);

    const remainingQty =
      order_qty -
      (isPlanGreaterCurrentDate && planQty) -
      (isCurrentGreaterEqualPlanDate && actualQty);

    return remainingQty || 0;
  };

  const onFinish = useCallback(
    async (editMode, id) => {
      // Form values
      const formValues = form.getFieldsValue();
      const remaining_qty_form_value = form.getFieldValue("remaining_qty");

      // Show error if totalPlanCurveQty is greater than order_qty_form_value
      if (remaining_qty_form_value < 0) {
        HandleNotification(
          "error",
          "bottomRight",
          "Total Plan Qty is greater than Remaining Qty. Reduce the input value.",
          null,
        );
        return;
      }

      const query = editMode ? `${PLAN_INITIATE}/${id}` : `${PLAN_INITIATE}`;
      const payload = {
        line_id: formValues?.line,
        inquiry_id:
          formValues?.inquiry_id ||
          formValues?.inquiry_id_when_not_selected ||
          null,
        style_id: formValues?.style_id || null,
        sub_style_id: formValues?.sub_style_id || null,
        buyer: formValues?.buyer?.id,
        buying_office: formValues?.buying_office?.id,
        sales_contract_id: formValues?.sales_contract?.id || null,
        po_map_id: formValues?.sales_contract_po_id || null,
        order_qty: formValues?.order_qty,
        pcd: formValues?.pcd,
        shipment_date: formValues?.shipment_date
          ? moment(formValues?.shipment_date).format("YYYY-MM-DD")
          : null,
        sam: formValues?.smv,
        mp: formValues?.no_of_workers,
        hourly_production: formValues?.per_hour_target,
        order_status: formValues?.order_status_name || null,
        start_date: formValues?.start_date
          ? moment(formValues?.start_date).format("YYYY-MM-DD")
          : null,
        end_date: isArrayAndHasValue(planningDetails)
          ? moment(planningDetails[planningDetails.length - 1].date).format(
              "YYYY-MM-DD",
            )
          : null,
        is_apply_learning_curve: formValues?.is_apply_learning_curve ? 1 : 0,
        planned_qty: 0, //Always 0
        planned_mh: 0, //Always 0
        actual_qty: formValues?.actual_qty || null,
        actual_mh: formValues?.actual_mh || null,
        remaining_qty: calculateRemainingQty(formValues),
        factory_id: formValues?.sub_contract_factory_id || null,
        details:
          isArrayAndHasValue(planningDetails) &&
          planningDetails?.map((item, index) => {
            return {
              day_id: item?.day,
              details_date: item?.date,
              learning_curve_mh:
                item?.working_hours || item?.learning_curve_mh || 0,
              learning_curve_efficiency:
                item?.efficiency || item?.learning_curve_efficiency || 0,
              learning_curve_qty: item?.dayQty || item?.learning_curve_qty || 0,
              plan_curve_mh: item?.plan_curve_mh,
              plan_curve_efficiency: item?.plan_curve_efficiency,
              plan_curve_qty: item?.plan_curve_qty,
            };
          }),
        created_with: editMode ? formValues?.created_with : createdWith,
      };

      const response = editMode
        ? await putData(query, payload)
        : await postData(query, payload);

      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "bottomRight",
          `Plan ${editMode ? "updated" : "initiated"} successfully`,
          null,
        );
        if (!editMode) {
          // Remove all the tables
          setPlanningDetails(null);
          setExistingPlan(
            isArrayAndHasValue(response?.data?.existingPlan)
              ? response?.data?.existingPlan
              : null,
          );
          setOverlapPlans(null);
        }
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error initiating plan",
          null,
        );
      }
    },
    [form, planningDetails],
  );

  const onDeletePlan = (day) => {
    if (isArrayAndHasValue(planningDetails)) {
      const index = planningDetails.findIndex((item) => item.day === day);
      const newPlanningDetails =
        index !== -1 ? planningDetails.slice(0, index) : planningDetails;
      setPlanningDetails(newPlanningDetails);
    }
  };

  const onEditPlan = (day) => {
    const newPlanningDetails =
      isArrayAndHasValue(planningDetails) &&
      planningDetails?.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            isEnableField: item?.isEnableField ? false : true,
          };
        }
        return item;
      });
    setChangeStatus(null);
    setPlanningDetails(newPlanningDetails);
  };

  const onChangePlanValue = (day, value, context) => {
    // Set change status
    const status = context === "plan_curve_mh" ? "only_wh" : "only_efficiency";
    if (changeStatus) {
      if (changeStatus === "only_wh" && context === "plan_curve_mh") {
        setChangeStatus("only_wh");
      } else if (
        changeStatus === "only_efficiency" &&
        context === "plan_curve_efficiency"
      ) {
        setChangeStatus("only_efficiency");
      } else {
        setChangeStatus("both");
      }
    } else {
      setChangeStatus(status);
    }

    const newPlanningDetails =
      isArrayAndHasValue(planningDetails) &&
      planningDetails?.map((item) => {
        if (item?.day === day) {
          const SAM = inquiryStyleDetails?.smv;
          const Workers =
            inquiryStyleDetails?.no_of_workers ||
            form.getFieldValue("no_of_workers");

          let plan_curve_qty_value = null;
          let plan_curve_mh_value =
            context === "plan_curve_efficiency" ? item?.plan_curve_mh : null;
          let plan_curve_efficiency_value =
            context === "plan_curve_mh" ? item?.plan_curve_efficiency : null;

          if (context === "plan_curve_mh") {
            const calculatedValue =
              (value * 60 * Workers * item?.plan_curve_efficiency) / SAM;
            const finalValue = calculatedValue ? Math.ceil(calculatedValue) : 0;
            plan_curve_qty_value = finalValue;
            plan_curve_mh_value = value;
          }
          if (context === "plan_curve_efficiency") {
            const calculatedValue =
              (item?.plan_curve_mh * 60 * Workers * value) / SAM;
            const finalValue = calculatedValue ? Math.ceil(calculatedValue) : 0;
            plan_curve_qty_value = finalValue;
            plan_curve_efficiency_value = value;
          }

          return {
            ...item,
            edited: true,
            plan_curve_efficiency: plan_curve_efficiency_value,
            plan_curve_mh: plan_curve_mh_value,
            plan_curve_qty: plan_curve_qty_value,
          };
        }
        return item;
      });
    setPlanningDetails(newPlanningDetails);
  };

  const planDataChangeOnLC = (value) => {
    const planningDetailsCopy = isArrayAndHasValue(planningDetails)
      ? [...planningDetails]
      : [];
    const maxValueWH = planningDetailsCopy?.reduce((acc, current) => {
      return (acc = acc > current.working_hours ? acc : current.working_hours);
    }, 0);
    const maxValueEfficiency = planningDetailsCopy?.reduce((acc, current) => {
      return (acc = acc > current.efficiency ? acc : current.efficiency);
    }, 0);
    const maxValueDayQty = planningDetailsCopy?.reduce((acc, current) => {
      return (acc = acc > current.dayQty ? acc : current.dayQty);
    }, 0);

    if (value) {
      planningDetailsCopy.forEach((item) => {
        item.plan_curve_mh = item.working_hours;
        item.plan_curve_efficiency = item.efficiency;
        item.plan_curve_qty = item.dayQty;
      });
    } else {
      planningDetailsCopy.forEach((item) => {
        item.plan_curve_mh = maxValueWH;
        item.plan_curve_efficiency = maxValueEfficiency;
        item.plan_curve_qty = maxValueDayQty;
      });
    }

    setPlanningDetails(planningDetailsCopy);
  };

  const regenerateOnline = async (
    data,
    resultArray,
    totalPlanCurveQty,
    daysToDelete,
  ) => {
    // Get values from form
    const order_qty_form_value = form.getFieldValue("order_qty");
    const existing_plan_sum_form_value =
      form.getFieldValue("existing_plan_sum");
    const line = form.getFieldValue("line");
    const smv = form.getFieldValue("smv");
    const no_of_workers = form.getFieldValue("no_of_workers");
    const start_date = form.getFieldValue("start_date");

    const remaining_qty =
      Number(order_qty_form_value) -
      ((existing_plan_sum_form_value || 0) + (totalPlanCurveQty || 0));

    // Show error if planned qty is greater than order_qty
    if (remaining_qty < 1) {
      HandleNotification(
        "error",
        "bottomRight",
        "Total Plan Qty is greater than Remaining Qty. Reduce the input value.",
        null,
      );
      return;
    }

    const query = `${REGENERATE_PLAN}`;
    const payload = {
      day: data.day,
      plan_start_date: start_date
        ? moment(start_date).format("YYYY-MM-DD")
        : null,
      date: data.date,
      plan_curve_mh: data.plan_curve_mh,
      plan_curve_efficiency: data.plan_curve_efficiency,
      plan_curve_qty: data.plan_curve_qty,
      remaining_qty: remaining_qty,
      smv: smv,
      no_of_workers: no_of_workers,
      daysToDelete: daysToDelete,
      change_status: changeStatus,
      line_id: line,
    };

    const response = await postData(query, payload);

    if (response && response?.code === 200) {
      const planningXtraDays = isArrayAndHasValue(response?.data?.days)
        ? response?.data?.days
        : [];
      const overlapPlans = isArrayAndHasValue(response?.data?.overlap_plans)
        ? response?.data?.overlap_plans
        : [];
      const resultArrayCopy = isArrayAndHasValue(resultArray)
        ? [...resultArray]
        : null;
      // Turn edited flag to false for current selected plan day
      const foundItem =
        isArrayAndHasValue(resultArrayCopy) &&
        resultArrayCopy.find((item) => item?.day === data?.day);
      if (foundItem) {
        foundItem["edited"] = false;
      }

      setChangeStatus(null);
      setPlanningDetails([...resultArrayCopy, ...planningXtraDays]);
      setOverlapPlans(overlapPlans);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error regenerating plan",
        null,
      );
    }
  };

  const onRegenerate = (record) => {
    const planningDetailsCopy = isArrayAndHasValue(planningDetails)
      ? [...planningDetails]
      : [];

    const foundDayIndex = planningDetailsCopy?.findIndex(
      (item) => item?.day === record?.day,
    );
    const daysToDelete = planningDetailsCopy.slice(foundDayIndex + 1);
    // Delete all the items after the found index
    const resultArray =
      foundDayIndex !== -1
        ? planningDetailsCopy.slice(0, foundDayIndex + 1)
        : planningDetailsCopy;
    setPlanningDetails(resultArray);

    //Calculate total plan curve after removing (state still not updated)
    const totalPlanCurveQty = resultArray?.reduce((acc, current) => {
      return acc + current.plan_curve_qty;
    }, 0);

    regenerateOnline(record, resultArray, totalPlanCurveQty, daysToDelete);
  };

  const getPlanDetails = useCallback(async (id) => {
    const query = `${PLAN_INITIATE}/${id}/show`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      setSelectedPlanDetails(response?.data?.data);
    } else {
      HandleNotification("error", "bottomRight", "Error getting data", null);
    }
  }, []);

  const onSelectBuyerInquiry = (value) => {
    const foundItem = options?.inquiryList?.find((item) => item.id === value);
    const styleId = foundItem?.style_info?.id;
    const styleObject = foundItem?.style_info;

    const subStyleList = isArrayAndHasValue(styleObject?.sub_style)
      ? styleObject?.sub_style
      : [];
    setOptions((prev) => ({ ...prev, subStyleList }));

    const isExistsStyle = options?.styleList?.find(
      (item) => item?.id === styleObject?.id,
    );

    if (!isExistsStyle && styleObject) {
      const styleList = isArrayAndHasValue(options?.styleList)
        ? [...options?.styleList, styleObject]
        : [{ styleObject }];
      setOptions((prev) => ({ ...prev, styleList }));
    }

    if (styleId) {
      // Set style id in form
      form.setFieldsValue({
        style_id: styleId,
      });

      // Change other fields based on selected style
      onSelectStyle(styleId, value);
    } else {
      // Reset style id field
      form.resetFields(["style_id", "sub_style_id"]);

      // Set data to form fields
      form.setFieldsValue({
        buying_office: {
          id: foundItem?.buying_office_info?.id,
          name: foundItem?.buying_office_info?.name,
        },
        buyer: {
          id: foundItem?.buyer_info?.id,
          name: foundItem?.buyer_info?.name,
        },
        description: foundItem?.description,
        subcontract_status: foundItem?.subcontract_status ? 1 : 0,
        sub_contract_factory_id:
          foundItem?.sub_contract_factory_id ||
          foundItem?.factory_info?.id ||
          null,
        factory_name: foundItem?.factory_info?.name || null,
      });
    }
  };

  const onSelectStyle = (value, inquiry_id) => {
    const foundStyle = options?.styleList?.find((style) => style.id === value);
    const subStyles = foundStyle?.sub_style;

    if (!inquiry_id_watch) {
      const subStyleList = isArrayAndHasValue(subStyles) ? subStyles : [];
      setOptions((prev) => ({ ...prev, subStyleList }));
    }

    form.setFieldsValue({
      inquiry_id: inquiry_id || foundStyle?.inquiry_id || null,
      buying_office: {
        id: foundStyle?.buying_office_info?.id,
        name: foundStyle?.buying_office_info?.name,
      },
      buyer: {
        id: foundStyle?.buyer_info?.id,
        name: foundStyle?.buyer_info?.name,
      },
      sales_contract: {
        id: foundStyle?.active_sales_contract_map_info
          ?.active_sales_contract_info?.id,
        sales_contract_no:
          foundStyle?.active_sales_contract_map_info?.active_sales_contract_info
            ?.reference_no,
      },
      description: foundStyle?.description,
      sub_style_id: null,
      subcontract_status: foundStyle?.subcontract_status ? 1 : 0,
      sub_contract_factory_id:
        foundStyle?.sub_contract_factory_id ||
        foundStyle?.factory_info?.id ||
        null,
      factory_name: foundStyle?.factory_info?.name || null,
    });
  };

  const onSelectSubStyle = (value) => {
    const foundSubStyle = options?.subStyleList?.find(
      (item) => item?.id === value,
    );
    const foundStyleId = foundSubStyle?.style_info?.id;
    const isExistsStyle = options?.styleList?.find(
      (item) => item?.id === foundStyleId,
    );

    if (style_id_watch) {
      return;
    }

    if (!isExistsStyle) {
      const styleList = isArrayAndHasValue(options?.styleList)
        ? [...options?.styleList, foundSubStyle?.style_info]
        : [foundSubStyle?.style_info];
      setOptions((prev) => ({ ...prev, styleList }));
    }

    form.resetFields(["style_id"]);

    form.setFieldsValue({
      style_id: foundStyleId,
      buying_office: {
        id: foundSubStyle?.style_info?.buying_office_info?.id,
        name: foundSubStyle?.style_info?.buying_office_info?.name,
      },
      buyer: {
        id: foundSubStyle?.style_info?.buyer_info?.id,
        name: foundSubStyle?.style_info?.buyer_info?.name,
      },
      sales_contract: {
        id: foundSubStyle?.style_info?.active_sales_contract_map_info
          ?.active_sales_contract_info?.id,
        sales_contract_no:
          foundSubStyle?.style_info?.active_sales_contract_map_info
            ?.active_sales_contract_info?.reference_no,
      },
      description: foundSubStyle?.style_info?.description,
      inquiry_id: foundSubStyle?.style_info?.inquiry_id,
      subcontract_status: foundSubStyle?.style_info?.subcontract_status ? 1 : 0,
      sub_contract_factory_id:
        foundSubStyle?.style_info?.sub_contract_factory_id ||
        foundSubStyle?.style_info?.factory_info?.id ||
        null,
      factory_name: foundSubStyle?.style_info?.factory_info?.name || null,
    });
  };

  const onChangeCreatedStatus = (status) => {
    setCreatedWith(status);
  };

  const onDeleteInitiatePlan = async (id) => {
    const query = `/api/productionPlanning/plan_initiate/${id}`;
    const response = await deleteData(query);

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        "Plan deleted successfully",
        null,
      );
      navigate("/production-planning/dashboard");
    } else {
      HandleNotification("error", "bottomRight", "Error deleting plan", null);
    }
  };

  return (
    <ProductionPlanningContext.Provider
      value={{
        form,
        options,
        setOptions,
        onGenerate,
        onFinish,
        planningDetails,
        setPlanningDetails,
        existingPlan,
        setExistingPlan,
        overlapPlans,
        setOverlapPlans,
        planningOverlapping,
        setPlanningOverlapping,
        onInquiryAndStyleChange,
        inquiryStyleDetails,
        onDeletePlan,
        onEditPlan,
        onChangePlanValue,
        planDataChangeOnLC,
        onRegenerate,
        getPlanDetails,
        selectedPlanDetails,
        getInquiryList,
        getStyleList,
        getSubStyleList,
        onSelectBuyerInquiry,
        onSelectStyle,
        onSelectSubStyle,
        onChangeCreatedStatus,
        shipmentDateSetter,
        onDeleteInitiatePlan,
      }}
    >
      {children}
    </ProductionPlanningContext.Provider>
  );
};

export default ProductionPlanningContextProvider;
