import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Table, Typography } from "antd";
import { EXPORT_INVOICE_TRACKER } from "apiServices/API_ENDPOINTS";
import { deleteData, getData } from "apiServices/common";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  EXPORT_INVOICE_EXPENSE_TRACKER_CREATE_PERMISSION,
  EXPORT_INVOICE_EXPENSE_TRACKER_DELETE_PERMISSION,
  EXPORT_INVOICE_EXPENSE_TRACKER_EDIT_PERMISSION,
  EXPORT_INVOICE_EXPENSE_TRACKER_VIEW_PERMISSION,
} from "routes/permissions";
import { hasPermission } from "utils/functions";

export const ExportInvoiceExpense = (props) => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rangeData, setRangeData] = useState(null);

  // Route
  const navigate = useNavigate();

  // Antd Constants
  const { Title } = Typography;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  const columns = [
    {
      title: "Sales Contract",
      dataIndex: ["sales_contract", "reference_no"],
      key: "reference_no",
    },
    {
      title: "Buyer",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_id",
    },
    {
      title: "Order Qty (pc)",
      dataIndex: "order_qty",
      key: "order_qty",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
    },
    {
      title: "Invoice Value",
      dataIndex: "invoice_value",
      key: "invoice_value",
    },
    {
      title: "CO",
      dataIndex: "co_fee",
      key: "co_fee",
    },
    {
      title: "BL",
      dataIndex: "bl_fee",
      key: "bl_fee",
    },
    {
      title: "BL Surrender",
      dataIndex: "bl_surrender_fee",
      key: "bl_surrender_fee",
    },
    {
      title: "C&F COST PAID",
      dataIndex: "export_cndf_cost",
      key: "export_cndf_cost",
    },
    {
      title: "COURIER COST",
      dataIndex: "courier_cost",
      key: "courier_cost",
    },
    {
      title: "TRANSPORT COST",
      dataIndex: "export_transport_cost",
      key: "export_transport_cost",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="cursore-pointer">
          <Dropdown overlay={menu(record)}>
            <MoreOutlined style={{ fontSize: "20px" }} />
          </Dropdown>
        </div>
      ),
    },
  ];

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  // Function to get styles list
  const getAllPermitList = useCallback(
    async (passedObject) => {
      let filter = passedObject;
      const customQuery = `${EXPORT_INVOICE_TRACKER}/?page=${currentPage}`;
      let res = await getData(customQuery, false, filter);

      if (res) {
        let masterData = res?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage],
  );

  useEffect(() => {
    setLoading(true);
    getAllPermitList(filterData);
  }, [currentPage, filterData, getAllPermitList]);

  const menu = (data) => (
    <Menu
      items={[
        {
          label: (
            <Link
              to={`/import-export-expense-tracking/export-invoice-expense/update/${data.id}`}
            >
              <EditOutlined /> Edit
            </Link>
          ),
          key: "0",
          disabled: !hasPermission([
            EXPORT_INVOICE_EXPENSE_TRACKER_EDIT_PERMISSION,
          ]),
        },
        {
          label: (
            <Link
              to={`/import-export-expense-tracking/export-invoice-expense/view/${data.id}`}
            >
              <EyeOutlined /> View
            </Link>
          ),
          key: "1",
          disabled: !hasPermission([
            EXPORT_INVOICE_EXPENSE_TRACKER_VIEW_PERMISSION,
          ]),
        },
        {
          label: (
            <dev
              onClick={async () => {
                await deleteData(`${EXPORT_INVOICE_TRACKER}/${data.id}`, false);
                getAllPermitList(filterData);
              }}
            >
              <DeleteOutlined /> Delete
            </dev>
          ),
          key: "2",
          disabled: !hasPermission([
            EXPORT_INVOICE_EXPENSE_TRACKER_DELETE_PERMISSION,
          ]),
        },
      ]}
    />
  );

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const onAdd = () => {
    navigate("/import-export-expense-tracking/export-invoice-expense/create");
  };

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={permitList}
        filterAPI={getAllPermitList}
        // showFilter={showFilter}
        // setShowFilter={setShowFilter}
        // filterName="style"
        showFilter={false}
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                // refresh={refresh}
                // setRefresh={setRefresh}
                handleAddItemOpen={onAdd}
                hideFilterButton
                disabled={
                  !hasPermission([
                    EXPORT_INVOICE_EXPENSE_TRACKER_CREATE_PERMISSION,
                  ])
                }
                addButtonText={
                  hasPermission([
                    EXPORT_INVOICE_EXPENSE_TRACKER_CREATE_PERMISSION,
                  ])
                    ? "Add New"
                    : "No Permission"
                }
                // showFilter={showFilter}
                // setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Export Invoice Expense</Title>}
          />
        }
        content={
          <>
            {/* {memoizedCustomFilter} */}
            <div className="table" style={{ marginTop: 10, padding: 10 }}>
              <Table
                loading={loading}
                dataSource={permitList}
                columns={columns}
                scroll={{
                  x: "max-content",
                }}
                pagination={{
                  showSizeChanger: false,
                  current: currentPage,
                  total: totalData,
                  pageSize: rangeData,
                  onChange: (page) => paginate(page),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default ExportInvoiceExpense;
