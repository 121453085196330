// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-dashboard-carousel ul.slick-dots.slick-dots-top li {
  background-color: rgb(238, 238, 238) !important;
}
.app-dashboard-carousel ul.slick-dots.slick-dots-top li.slick-active button {
  background-color: #1890ff !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/AppDashboard/style.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;AACjD;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".app-dashboard-carousel ul.slick-dots.slick-dots-top li {\n  background-color: rgb(238, 238, 238) !important;\n}\n.app-dashboard-carousel ul.slick-dots.slick-dots-top li.slick-active button {\n  background-color: #1890ff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
